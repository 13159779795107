const API_ENDPOINT_SERVER =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:8000'
    : import.meta.env.VITE_API_ENDPOINT_SERVER;

export const LOGIN_API_ENDPOINT = API_ENDPOINT_SERVER + '/users/auth/login/';
export const USERS_API_ENDPOINT = API_ENDPOINT_SERVER + '/users/users/';
export const CREATE_USER_API_ENDPOINT =
  API_ENDPOINT_SERVER + '/users/create-user/';
export const UPDATE_USER_API_ENDPOINT =
  API_ENDPOINT_SERVER + '/users/update-user/';
export const CHANGE_PASSWORD_API_ENDPOINT =
  API_ENDPOINT_SERVER + '/users/auth/password/change/';
export const ITEMS_ENDPOINT = API_ENDPOINT_SERVER + '/library/items/';
export const INSTANCES_ENDPOINT = API_ENDPOINT_SERVER + '/bib/instances/';
export const COLLECTIONS_ENDPOINT = API_ENDPOINT_SERVER + '/bib/collections/';
export const CREATE_ITEM_AND_INSTANCE_ENDPOINT =
  API_ENDPOINT_SERVER + '/library/create-item-and-instance/';
export const CREATE_ITEM_COPIES_ENDPOINT =
  API_ENDPOINT_SERVER + '/library/create-item-copies/';
export const GET_ITEM_BY_ISBN_ENDPOINT =
  API_ENDPOINT_SERVER + '/search/item/isbn';
export const GET_ITEM_BY_COLLECTION_VOLUME =
  API_ENDPOINT_SERVER + '/search/item/collection-volume';
export const SEARCH_TITLES_ENDPOINT =
  API_ENDPOINT_SERVER + '/search/item/title';
export const SEARCH_CONTENT_ENDPOINT = API_ENDPOINT_SERVER + '/search/title';
export const SEARCH_AUTHOR_ENDPOINT = API_ENDPOINT_SERVER + '/search/author';
export const SEARCH_PERSONS_ENDPOINT = API_ENDPOINT_SERVER + '/search/persons';
export const SEARCH_COLLECTION_ENDPOINT =
  API_ENDPOINT_SERVER + '/search/collection';
export const GET_MY_LIBRARY_ENDPOINT =
  API_ENDPOINT_SERVER + '/library/get-my-library-details';
export const SET_MY_LIBRARY_ENDPOINT =
  API_ENDPOINT_SERVER + '/library/set-my-library-details';
export const LIBRARY_ENDPOINT = API_ENDPOINT_SERVER + '/library/libraries/';
export const LIST_LIBRARY_INSTANCES_ENDPOINT =
  API_ENDPOINT_SERVER + '/library/list-library-instances/';
export const LIBRARY_BOOKS_COUNT_ENDPOINT =
  API_ENDPOINT_SERVER + '/library/item/count';
export const PUBLISHERS_DATA_ENDPOINT = API_ENDPOINT_SERVER + '/bib/publishers';
export const PUBLICATION_PLACES_DATA_ENDPOINT =
  API_ENDPOINT_SERVER + '/bib/publication-places';
export const GET_ITEM_BY_ACCESSION_CODE_ENDPOINT =
  API_ENDPOINT_SERVER + '/library/get-item-by-accession-code/';
export const DOWNLOAD_CSV_ENDPOINT = 
API_ENDPOINT_SERVER + '/library/items/download-csv';
export const INSTANCE_COMMENTS =
  API_ENDPOINT_SERVER + '/library/instance-comments/';