import React from 'react';
import { Box, Grid } from '@mui/material';
import GridItem from './GridItem';
import { Instance } from '../types';
import styled from 'styled-components';

interface ItemGridProps {
  items: Instance[];
}

const ItemGrid: React.FC<ItemGridProps> = ({ items }) => (
  <StyledBox>
    <Grid container>
      {items.map((bookData) => (
        <GridItem key={bookData.id} bookData={bookData} />
      ))}
    </Grid>
  </StyledBox>
);

export default ItemGrid;


const StyledBox = styled(Box)`
  width: 100%
`;
