import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import Close from '@mui/icons-material/Close';
import MyLocationOutlinedIcon from '@mui/icons-material/MyLocationOutlined';
import { useTranslation } from 'react-i18next';
import { Typography, Box, Button, Input, IconButton, OutlinedInput, FormControl, InputLabel, InputAdornment, useMediaQuery } from '@mui/material'
import { useState } from 'react';
import KnowMoreModal from './KnowMoreModal';
import QRPreview from './QRPreview';

interface LibraryDetailsProps {
    selectedImage: string | null;
    handleImageChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    setSelectedImage: React.Dispatch<React.SetStateAction<string | null>>;
    libraryName: string | null;
    setLibraryName: React.Dispatch<React.SetStateAction<string | null>>;
    shortName: string | null;
    setShortName: React.Dispatch<React.SetStateAction<string | null>>;
    location: string | null;
    setLocation: React.Dispatch<React.SetStateAction<string | null>>;
    geolocation: string | null;
    setGeoLocation: React.Dispatch<React.SetStateAction<string | null>>;
    handleLocationClick: () => void;
}
  
export default function LibraryDetails({
    selectedImage,
    handleImageChange,
    setSelectedImage,
    libraryName,
    setLibraryName,
    shortName,
    setShortName,
    location,
    setLocation,
    handleLocationClick,
}: LibraryDetailsProps) {
  
    const isSmallDevice = useMediaQuery('(max-height: 740px)');
    const { t } = useTranslation();
    const [isModalOpen, setIsModalOpen] = useState(false);
  
    const handleModalOpen = () => setIsModalOpen(true);
    const handleModalClose = () => setIsModalOpen(false);

    const handleInputChange = (e : any) => {
            const value = e.target.value;
            const englishOnlyValue = value.replace(/[^a-zA-Z0-9,.\s]/g, '')
            const singleSpacedValue = englishOnlyValue.replace(/\s{2,}/g, ' ');
            setLocation(singleSpacedValue);
    };
  
    return (
      <Box width={'100vw'} maxWidth={'100vw'} pb={isSmallDevice ? '150px' : 0}>
        <Typography sx={{ padding: '16px', fontWeight: 400, fontSize: 14, color: '#242424', letterSpacing: '0.72px' }}>
          {t('Please enter all the required(*) information')}
        </Typography>

        <Typography sx={{px: '16px', fontWeight: 600, fontSize: 16, color: '#242424'}}>
            {t('Library Details')}
        </Typography>

        <Box flexDirection={'row'} p={'16px'} display={'flex'}>
            {selectedImage && (
            <Box sx={{ border: '1px solid #DFDFDF', borderRadius: '11px', position: 'relative', width: '112px', minWidth: '112px', minHeight: '112px', height: '112px' }}>
            <img src={selectedImage} alt="Selected Image" style={{ width: '112px', height: '112px', borderRadius: '11px' }} />
                <IconButton aria-label="remove-image" onClick={() => setSelectedImage(null)} sx={{ position: 'absolute', top: 0, right: 0, color: 'error' }}>
                <Close color='error'/>
                </IconButton>
            </Box>
            )}
            {!selectedImage && (
            <Button
                component="label"
                role={undefined}
                variant='outlined'
                tabIndex={-1}
                sx={{ width: '112px', height: '108px', flexDirection: 'column', textTransform: 'none', borderRadius: '11px', borderWidth: '1px', borderColor: '#DFDFDF', minWidth: '112px', backgroundColor: 'white' }}
            >
                <Typography sx={{ pb: '20px', color: '#949494', fontSize: '12px' }}>
                {t('Logo')}
                </Typography>
                <CloudUploadOutlinedIcon />
                <Input
                sx={{ clip: 'rect(0 0 0 0)', clipPath: 'inset(50%)', height: 1, overflow: 'hidden', position: 'absolute', bottom: 0, left: 0, whiteSpace: 'nowrap', width: 1 }}
                type="file"
                inputProps={{ accept: "image/png, image/jpeg" }}
                onChange={handleImageChange}
                />
            </Button>
            )}

            <Box display={'flex'} flexDirection={'column'} sx={{marginLeft: '16px', width: '-webkit-fill-available', justifyContent: 'space-between'}}>

            <FormControl variant="outlined" fullWidth required>
                <InputLabel sx={{fontWeight: 400, color: '#949494', fontSize: '14px'}} htmlFor="library-name">{t('Library Name')}</InputLabel>
                <OutlinedInput
                value={libraryName ? libraryName : ''} 
                onChange={(e) => setLibraryName(e.target.value.replace(/\s{2,}/g, ' '))}
                sx={{background: 'white', borderRadius: '11px', fontSize: '14px'}}
                id="library-name"
                label={t("Library Name")}
                inputProps={{style: {padding: '14px 16px', textAlign: 'left'}}}
                />
            </FormControl>

            <FormControl variant="outlined" fullWidth required>
                <InputLabel sx={{fontWeight: 400, color: '#949494', fontSize: '14px'}} htmlFor="short-name">{t('Short Name')}</InputLabel>
                <OutlinedInput
                value={shortName ? shortName : ''} 
                onChange={(e) => setShortName(e.target.value.replace(/\s{2,}/g, ''))}
                sx={{background: 'white', borderRadius: '11px', fontSize: '14px'}}
                id="short-name"
                label={t("Short Name")}
                inputProps={{
                    style: {padding: '14px 16px', textAlign: 'left'},
                    maxLength: 4
                }}
                />
            </FormControl>
        
            </Box>
        </Box>
  
        <FormControl variant="outlined" fullWidth required sx={{paddingX: '16px'}}>
            <InputLabel sx={{fontWeight: 400, color: '#949494', fontSize: '14px', paddingX: '22px'}} htmlFor="location">{t('Library Location')}</InputLabel>
            <OutlinedInput
            value={location ? location : ''} 
            onChange={handleInputChange}

            sx={{background: 'white', borderRadius: '11px', fontSize: '14px'}}
            id="location"
            label={t("Library Location")}
            endAdornment={
                <InputAdornment position="end">
                <IconButton
                    aria-label="location"
                    onClick={handleLocationClick}
                    edge='end'
                    color='primary'
                >
                    <MyLocationOutlinedIcon />
                </IconButton>
                </InputAdornment>
            }
            inputProps={{style: {padding: '14px 16px'}}}
            />
        </FormControl>
        
        <QRPreview shortName={shortName} t={t} handleModalOpen={handleModalOpen} />

        <KnowMoreModal isOpen={isModalOpen} onClose={handleModalClose} />

        </Box>
    );
}
  