import React from 'react';
import { Box, Typography } from '@mui/material';
import { Instance } from '../types';
import styled from 'styled-components';

interface NoItemsProps {
  t: (key: string) => string;
  items: Instance[];
  loading: boolean;
  search: string;
  filter: string;
  noitem: string;
}

const NoItems: React.FC<NoItemsProps> = ({ t, items, loading, search, noitem, filter }) => {
  const getMessage = () => {
    if (filter === 'MANUSCRIPT') {
      return t('No manuscripts catalogued yet');
    } else if (filter === 'WOODBLOCK') {
      return t('No woodblocks catalogued yet');
    } else if (items.length === 0 && !loading && search === '') {
      return t('No items catalogued yet');
    } else if (items.length === 0 && !loading && search !== '') {
      return t('No items match your search');
    }
    return '';
  };
  return (
    !loading && items.length === 0 && (
      <StyledBox>
        <NoItemsImage src={noitem} alt="No Items" />
        <StyledTypography>
          {getMessage()}
        </StyledTypography>
      </StyledBox>
    )
  )
  
};

export default NoItems;


const StyledBox = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex: 1;
  height: calc(100vh - 288px);
`;

const NoItemsImage = styled.img`
  width: 80px;
  height: 80px;
`;

const StyledTypography = styled(Typography)`
  && {
    font-weight: 600;
    padding: 16px;
  }
`;