import { useMutation, useQueryClient } from '@tanstack/react-query';

export const useGenericMutation = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    
    mutationFn: async (params: { mutationFn: () => Promise<any>; queryKeys: string[] }) => {
      const { mutationFn } = params;
      const result = await mutationFn();
      return result;
    },
    onSuccess: (data, variables) => {
      const { queryKeys } = variables;
      queryKeys.forEach((key) => {
        queryClient.invalidateQueries({ queryKey: [key] });
      });
    },
  });

  return mutation;
};

export default useGenericMutation;
