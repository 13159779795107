import { Container, Typography } from '@mui/material';
import noitem from '../assets/img/noitem.png';
import { useTranslation } from 'react-i18next';

export default function ErrorScreen() {
    const { t } = useTranslation();

    return (
      <Container sx={{ height: '100vh', justifyContent: 'center', alignItems: 'center', display: 'flex', flexDirection: 'column', textAlign: 'center'}}>
       <img src={noitem} width="80px" height="80px" alt="No Items" />
       <Typography p={1} pt={4} fontWeight={600}>
          {t('Something went wrong.')}
       </Typography>
       <Typography p={0.5} fontWeight={600}>
          {t('Kindly reload the page.')}
       </Typography>
       <Typography p={0.5} fontWeight={600}>
          {t('Kindly contact the administrator, if the issue persists.')}
       </Typography>
      </Container>
    )
}
