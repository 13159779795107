import React from 'react';
import { Box } from '@mui/material';
import LibraryBook from './LibraryBook';
import { Instance } from '../types';
import styled from 'styled-components';

interface ItemListProps {
  items: Instance[];
}

const ItemList: React.FC<ItemListProps> = ({ items }) => (
  <StyledBox>
    {items.map((bookData) => (
      <LibraryBook key={bookData.id} bookData={bookData} />
    ))}
  </StyledBox>
);

export default ItemList;

const StyledBox = styled(Box)`
  padding: 0 16px;
  padding-bottom: 16px;
`;
