import { Box, Button, Chip, IconButton, Menu, MenuItem, Typography } from '@mui/material';
import { FilterAlt as FilterAltIcon, GridView as GridViewIcon, ViewList as ViewListIcon } from '@mui/icons-material';

import { Instance } from '../types';
import React from 'react';
import styled from 'styled-components';
import { toTitleCase } from '../utils/func';

interface CataloguedItemsProps {
  t: (key: string) => string;
  items: Instance[];
  totalBookCount: number;
  loading: boolean;
  open: boolean;
  anchorEl: HTMLElement | null;
  handleMenuOpen: (event: React.MouseEvent<HTMLButtonElement>) => void;
  handleMenuClose: () => void;
  handleMenuItemClick: (option: string) => void;
  filter: string;
  setFilter: (option: string) => void;
  isGridView: boolean;
  handleToggleView: () => void;
}

const CataloguedItems: React.FC<CataloguedItemsProps> = ({ t, items, totalBookCount, loading, open, anchorEl, handleMenuOpen, handleMenuClose, handleMenuItemClick, filter, setFilter, isGridView, handleToggleView }) => (
  (
    <StyledBox>
      <StyledBoxInner>
        <StyledTypography>
          {t(toTitleCase(filter==''? 'Catalogued Items': filter ?.replace(/_/g, ' ')))}
        </StyledTypography>
        <StyledChip label={String(totalBookCount)}/>
        {filter && (
          <Button onClick={() => setFilter("")} color="primary">
            {t('Clear')}
          </Button>
        )}
      </StyledBoxInner>
      <Box>
        <StyledIconButton id="basic-button" aria-controls={open ? 'basic-menu' : undefined} aria-haspopup="true" aria-expanded={open ? 'true' : undefined} onClick={handleMenuOpen}>
          <FilterAltIcon />
        </StyledIconButton>
        <Menu id="basic-menu" anchorEl={anchorEl} open={open} onClose={handleMenuClose} MenuListProps={{ 'aria-labelledby': 'basic-button' }}>
          <MenuItem onClick={() => handleMenuItemClick('BOOK')}>{t('Books')}</MenuItem>
          <MenuItem onClick={() => handleMenuItemClick('MANUSCRIPT')}>{t('Manuscripts')}</MenuItem>
          <MenuItem onClick={() => handleMenuItemClick('WOODBLOCK')}>{t('Woodblocks')}</MenuItem>
          <MenuItem onClick={() => handleMenuItemClick('MODERN_PUBLICATION')}>{t('Modern Publication')}</MenuItem>
        </Menu>
        <IconButton onClick={handleToggleView}>
          {isGridView ? <ViewListIcon /> : <GridViewIcon />}
        </IconButton>
      </Box>
    </StyledBox>
  )
);

export default CataloguedItems;


const StyledBox = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
`;

const StyledBoxInner = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const StyledChip = styled(Chip)`
  height: 24px;
  margin: 0 16px;
  background-color: #D1DBE9;
  color: #1458BD;
`;

const StyledTypography = styled(Typography)`
  && {
    font-weight: 600;
    font-size: 16px;
  }
`;

const StyledIconButton = styled(IconButton)`
  && {
    text-transform: none;
  }
`;
