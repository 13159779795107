import { useState, useEffect } from 'react';
import Snackbar from '@mui/material/Snackbar';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

export const useNetworkError = () => {
  const [open, setOpen] = useState(false);

  const handleConnectionChange = (event: Event) => {
    if (event.type === "offline") {
      setOpen(true);
    }
    if (event.type === "online") {
      window.location.reload();
      setOpen(false);
    }
  };

  useEffect(() => {
    window.addEventListener('online', handleConnectionChange);
    window.addEventListener('offline', handleConnectionChange);

    return () => {
      window.removeEventListener('online', handleConnectionChange);
      window.removeEventListener('offline', handleConnectionChange);
    };
  }, []);

  const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const handleRefresh = () => {
    window.location.reload();
  };

  const NetworkErrorSnackbar = (
    <Snackbar
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={open}
      onClose={handleClose}
      message="You are currently offline."
      action={
        <>
          <Button color="secondary" size="small" onClick={handleRefresh} style={{ textTransform: 'none' }}>
            Refresh
          </Button>
          <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
            <CloseIcon fontSize="small" />
          </IconButton>
        </>
      }
    />
  );

  return NetworkErrorSnackbar;
};
