import {
  COLLECTIONS_ENDPOINT,
  CREATE_ITEM_AND_INSTANCE_ENDPOINT,
  GET_ITEM_BY_ACCESSION_CODE_ENDPOINT,
  GET_ITEM_BY_COLLECTION_VOLUME,
  GET_ITEM_BY_ISBN_ENDPOINT,
  INSTANCES_ENDPOINT,
  ITEMS_ENDPOINT,
  ARCHIVED_ITEMS_ENDPOINT,
  LIST_LIBRARY_INSTANCES_ENDPOINT,
  PUBLICATION_PLACES_DATA_ENDPOINT,
  PUBLISHERS_DATA_ENDPOINT,
  SEARCH_AUTHOR_ENDPOINT,
  SEARCH_COLLECTION_ENDPOINT,
  SEARCH_CONTENT_ENDPOINT,
  SEARCH_PERSONS_ENDPOINT,
  SEARCH_TITLES_ENDPOINT,
} from '../utils/api';
import {
  EditItemData,
  Instance,
  ItemData,
  ItemInstanceDetailed,
  ItemInstance
} from '../types';
import { AuthenticatedApiHook } from '../types';
import { authenticatedFetch } from './utils';

export async function getCollections(query: string) {
  const response = await authenticatedFetch(
    `${SEARCH_COLLECTION_ENDPOINT}/?query=${query}`,
  );
  if (response.ok) {
    return await response.json();
  } else {
    // TODO: Add error handling
  }
}

export async function getOrCreateCollection(collectionName: string) {
  const response = await authenticatedFetch(
    `${COLLECTIONS_ENDPOINT}`,
    { title: collectionName },
    'POST',
  );
  if (response.ok) {
    return await response.json();
  } else {
    // TODO: add error handling
  }
}

export async function getAuthors(query: string) {
  const response = await authenticatedFetch(
    `${SEARCH_AUTHOR_ENDPOINT}/?query=${query}`,
  );
  if (response.ok) {
    return await response.json();
  } else {
    // TODO: add error handling
  }
}

export async function searchPersons(query: string) {
  const response = await authenticatedFetch(
    `${SEARCH_PERSONS_ENDPOINT}/?query=${query}`,
  );
  if (response.ok) {
    return await response.json();
  } else {
    // TODO: add error handling
  }
}

export async function getTitles(api, query: string) {
  const response = await authenticatedFetch(
    `${SEARCH_TITLES_ENDPOINT}/?query=${query}`,
  );
  if (response.ok) {
    return await response.json();
  } else {
    // TODO: add error handling
  }
}

export async function getItemByISBN(
  authenticatedApiHook: AuthenticatedApiHook,
  query: string
) {
  const response = await authenticatedApiHook(`${GET_ITEM_BY_ISBN_ENDPOINT}/${query}/`);
  if (response) {
    const data = await response.json();
    return data;
  }
  return null;
}

export async function getItemByCollectionAndVolume(collection: string, volume_number: string) {
  try {
    const response = await authenticatedFetch(
      `${GET_ITEM_BY_COLLECTION_VOLUME}/?collection=${collection}&volume_number=${volume_number}`,
    );
    if (response.ok) {
      const response_data = await response.json();
      return { data: response_data, error: null };
    } else {
      return {
        data: null,
        error: response.error || `${response.status} ${response.statusText}`,
      };
    }
  } catch (error) {
    return { data: null, error: 'Error fetching item by Collection and Volume' };
  }
}

export async function getPublisherData(query: string) {
  try {
    const url = `${PUBLISHERS_DATA_ENDPOINT}/?search_publisher=${query}`;
    const response = await authenticatedFetch(url);
    if (response.ok) {
      const data = await response.json();
      return { data, error: null };
    } else {
      return {
        data: null,
        error: response.error || `${response.status} ${response.statusText}`,
      };
    }
  } catch (error) {
    return { data: null, error: 'Error fetching Publishers' };
  }
}

export async function getPublishingPlacesData(query: string) {
  try {
    const url = `${PUBLICATION_PLACES_DATA_ENDPOINT}/?search_publication_place=${query}`;
    const response = await authenticatedFetch(url);
    if (response.ok) {
      const data = await response.json();
      return { data, error: null };
    } else {
      return {
        data: null,
        error: response.error || `${response.status} ${response.statusText}`,
      };
    }
  } catch (error) {
    return { data: null, error: 'Error fetching Publication Places' };
  }
}

export async function getContent(query: string) {
  const response = await authenticatedFetch(
    `${SEARCH_CONTENT_ENDPOINT}/?query=${query}`,
  );
  if (response.ok) {
    return await response.json();
  } else {
    // TODO: add error handling
  }
}

export async function addOrUpdateItem(authenticatedApiHook: AuthenticatedApiHook,itemData: EditItemData) {
  let url = `${ITEMS_ENDPOINT}`;
  let method = 'POST';
  if (itemData.id) {
    url += `${itemData.id}/`;
    method = 'PATCH';
  }
  const response = await authenticatedApiHook(url, method,undefined,itemData);
  if (response) {
    return await response.json();
  } else {
    // TODO: add error handling
  }
}

type ItemOutput = {
  count: number;
  items: ItemInstanceDetailed[];
};
export async function getItem(itemId: string,archived?:boolean): Promise<ItemInstance | null> {
  let url: string;
  url = `${ITEMS_ENDPOINT}${itemId}/`;
  if(archived){
    url += `?archived=${archived}`;
  }
  const response = await authenticatedFetch(url);
  if (response.ok) {
    const data = await response.json();
    console.log("data", data);
    return data as ItemInstance;
  } else {
    console.error('Failed to get item');
    return null;
  }
}

export async function getItems(
  search?: string,
  instance?: string,
  onlyMyItems: boolean = false,
  offset: number = 0,
  limit: number = 10,
  archived: boolean = false,
  itemInstance: boolean = false,
  userId?: string,
): Promise<ItemOutput> {
  let url = `${ITEMS_ENDPOINT}?offset=${offset}&limit=${limit}`;
  if (onlyMyItems) {
    url += '&only_mine=true';
  }
  if (instance) {
    url += `&instance=${instance}`;
  }
  if (search) {
    url += `&search=${search}`;
  }
  if(archived){
    url += `&archived=${archived}`;
  }
  if(itemInstance){
    url += `&item_instance=${itemInstance}`;
  }
  if (userId) {
    url += `&user_id=${userId}`;
  }
  const response = await authenticatedFetch(url);
  if (response.ok) {
    const data = await response.json();
    const count: number = data['count']
    const items: ItemInstanceDetailed[] = data['results'];
    return {count, items};
  } else {
    console.error('Failed to get items');
    return {count:0,items:[]};
  }
}

export async function getInstance(instanceId: string) {
  const response = await authenticatedFetch(
    `${INSTANCES_ENDPOINT}${instanceId}/`,
  );
  if (response.ok) {
    const data = await response.json();
    return data;
  }
}

type InstancesOutput = {
  count: number;
  instances: Instance[];
};

export async function getLibraryInstances(
  authenticatedApiHook: AuthenticatedApiHook,
  search: string,
  offset: number,
  onlyMyItems: boolean = false,
  sort: string = 'recent',
  userId?: string,
  archived: boolean = false,
  itemType?: string,
): Promise<InstancesOutput> {
  let url = `${LIST_LIBRARY_INSTANCES_ENDPOINT}?search=${search}&offset=${offset}&only_mine=${onlyMyItems}&sort=${sort}&archived=${archived}`;
  if (userId) {
    url += `&user_id=${userId}`;
  }
  if (itemType) {
    url += `&item_type=${itemType}`;
  }
  const response = await authenticatedApiHook(url);
  if (response) {
    const data = await response.json();
    const instances: Instance[] = data['results'];
    return { count: data['count'], instances };
  } else {
    console.error('Failed to get library instances');
    return { count: 0, instances: [] };
  }
}

export async function createItemAndInstance(authenticatedApiHook: AuthenticatedApiHook,itemData: ItemData) {
  const response = await authenticatedApiHook(
    `${CREATE_ITEM_AND_INSTANCE_ENDPOINT}`,
    'POST',
    'multipart/form-data',
    itemData,      
  );
  if (response) {
    return await response.json();
  } else {
    // TODO: add error handling
  }
}

export async function updateInstance(itemData: ItemData) {
  const response = await authenticatedFetch(
    `${INSTANCES_ENDPOINT}${itemData.id}/`,
    itemData,
    'PATCH',
    'multipart/form-data',
  );
  if (response.ok) {
    const data = await response.json();
    return data;
  } else {
    console.error('Failed to create item');
  }
}

export async function deleteArchivedItem(itemId:string){
  let url = `${ITEMS_ENDPOINT}${itemId}/?archived=true`;
  try {
  const response = await authenticatedFetch(
    url,
    null,
    'DELETE',
  );
  if (response.ok && response.status === 204) {
    console.log('Item successfully deleted');
    return { success: true };
  } else {
    console.error('Failed to delete item');
    return { success: false, message: 'Failed to delete item' };
  }
} catch (error: unknown) {
  const message = error instanceof Error ? error.message : 'An unexpected error occurred';
  console.error('Error:', message);
  return { success: false, message };
}
}

export async function setItemArchived(itemId: string, isArchived: boolean) {
  let url = `${ITEMS_ENDPOINT}${itemId}/`
  // sending true when it is already a archived item and want to unarchive it
  if(!isArchived){
    url += `?archived=true`;
  }
  const response = await authenticatedFetch(
      url,
    { archived: isArchived },
    'PATCH',
  );
  if (response.ok) {
    const data = await response.json();
    return data;
  } else {
    console.error('Failed to archive item');
  }
}

export async function getItemByLibraryAndAccessionCode(
  uniqueLibraryId: string,
  accessionCode: string,
) {
  const response = await authenticatedFetch(
    `${GET_ITEM_BY_ACCESSION_CODE_ENDPOINT}?library_unique_id=${uniqueLibraryId}&accession_code=${accessionCode}`,
  );
  if (response.ok) {
    const content = await response.json();
    return content;
  } else {
    // Handle the error appropriately
    console.error('Failed to fetch item by accession code');
  }
}
