import React from 'react';
import { Fab } from '@mui/material';
import { Add as AddIcon } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const FabButton: React.FC = () => {
  const navigate = useNavigate();
  return (
    <StyledFab onClick={() => navigate('/add-item')}>
      <StyledAddIcon/>
    </StyledFab>
  );
};

export default FabButton;

const StyledFab = styled(Fab)`
  && {
    border-color: #6d2312;
    border-width: 2px;
    background-color: white;
    position: absolute;
    bottom: 80px;
    right: 16px;

    &:hover {
      background-color: white;
    }
  }
`;

const StyledAddIcon = styled(AddIcon)`
  color: #6d2312;
`;
