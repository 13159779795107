import {
  Box,
  Container,
  IconButton,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";

import AddIcon from '@mui/icons-material/Add';
import AssociatedLibrarian from "../components/AssociatedLibrarian";
import KeyboardArrowLeft  from '@mui/icons-material/KeyboardArrowLeft';
import { UserDetails } from "../types";
import { getUsers } from "../api/user";
import { useAuth } from "../context/AuthContext";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function AssociatedLibrarians() {

    const navigate = useNavigate();
    const [associateLibrarians, setAssociateLibrarians] = useState<UserDetails[]>([]);
    const { t } = useTranslation()
    const { authData } = useAuth()

    useEffect(() => {
        const fetchLibraryDetails = async () => {
            setAssociateLibrarians(await getUsers(true));          
        };
    
        fetchLibraryDetails();
      }, []);

    return (
    <Container disableGutters sx={{height: '100vh', overflowY: 'scroll', pb: 8}}>
        {/* Header Box */}
        <Box display={'flex'} flexDirection={'row'} p={'16px'} bgcolor={'white'} width={'100vw'} maxWidth={'100%'} justifyContent={'space-between'} alignItems={'center'}>
            <IconButton onClick={() => navigate('/landing')} style={{padding: 0}}>
                <KeyboardArrowLeft />
            </IconButton>
            <Typography fontSize={'16px'} fontWeight={500}>
                {t('Associate Librarians')}
            </Typography>
            <Box width={'30px'}></Box>
        </Box>

        <Box p={'16px'}>
            {authData?.user?.role == 'lsa' && <Box display={'flex'} flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'} py={1}>
                <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
                    <IconButton onClick={() => navigate("/add-user")} sx={{borderRadius: 50, borderColor: '#949494', borderWidth: '1px', borderStyle: 'solid'}}>
                        <AddIcon />
                    </IconButton>
                    <Typography px={'14px'} fontSize={'16px'} fontWeight={500}>
                        {t('Add new user')}
                    </Typography>
                </Box>
            </Box>}
            {associateLibrarians.map((u) => <AssociatedLibrarian key={u.id} userId={u.id} userName={u.full_name} cataloguedItems={u.book_count || 0}/>)}
        </Box>


    </Container>
  )
}
