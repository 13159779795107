import { Avatar, Box, Container, IconButton, Typography } from '@mui/material'

import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import logo from '../assets/img/toplogo.png'
import { useAuth } from '../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { APP_VERSION } from '../const';
import { downloadLibraryDetails } from '../api/library';

export default function Profile() {
  const navigate = useNavigate();
  const { authData, logout } = useAuth();
  const { t } = useTranslation()

  const roleNameMap = {
    'la': 'Library Admin',
    'lsa': 'Library Super Admin',
    'lu': 'Library User'
  }
  
  return (
    <Container disableGutters sx={{height: '100vh', overflowY: 'scroll', pb: 8}}>
      {/* Header Box */}
      <Box display={'flex'} flexDirection={'row'} p={'16px'} bgcolor={'white'} width={'100vw'} maxWidth={'100%'} justifyContent={'space-between'}>
          <img src={logo}/>
          <Typography fontSize={'16px'} fontWeight={500}>
              {t('Profile')}
          </Typography>
          <Box width={'30px'}></Box>
      </Box>

      {/* todo: set name photo and position accordingly */}
      <Box p={'16px'} display={'flex'} flexDirection={'column'} alignItems={'center'}>
        {/* <img src='https://picsum.photos/200'  style={{ width: 120, height: 120, borderRadius: 100 }}/> */}
        <Avatar sx={{ width: 120, height: 120, fontSize: 48, backgroundColor: '#F4F1E7', color: '#908056' }}>{`${roleNameMap[authData?.user?.role].split(' ')[0][0]}${roleNameMap[authData?.user?.role].split(' ')[1][0]}`}</Avatar>
        {/* {authData?.user?.full_name !== '' && <Avatar sx={{ width: 120, height: 120, fontSize: 48, backgroundColor: '#F4F1E7', color: '#908056' }}>{`${authData?.user?.full_name?.split(' ')[0][0]}${authData?.user?.full_name?.split(' ')[1][0]}`}</Avatar>} */}

        <Typography fontWeight={700} fontSize={'20px'} pt={2}>{authData?.user?.full_name}</Typography>
        <Typography fontWeight={400} fontSize={'16px'} color={'#908056'} pt={1} pb={2}>{roleNameMap[authData?.user?.role]}</Typography>
      </Box> 

      <Box px={'16px'} pt={'16px'}>
        <Box display={'flex'} flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'} pb={1}>
          <Typography fontWeight={600} fontSize={'18px'}>
            {t('Settings')}
          </Typography>
        </Box>
      </Box>

      <Box px={'16px'}>
        <Box display={'flex'} flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'} pb={1}  onClick={() => navigate('/language-settings')}>
          <Typography fontWeight={400} fontSize={'16px'}>
            {t('Language Preference')}
          </Typography>
          <IconButton sx={{paddingRight: 0}}>
            <ChevronRightIcon />
          </IconButton>
        </Box>
      </Box>

      <Box px={'16px'}>
        <Box display={'flex'} flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'} pb={1}  onClick={downloadLibraryDetails}>
          <Typography fontWeight={400} fontSize={'16px'}>
            {t('Download')}
          </Typography>
          <IconButton sx={{paddingRight: 0}}>
            <ChevronRightIcon />
          </IconButton>
        </Box>
      </Box>

      <Box px={'16px'}>
        <Box display={'flex'} flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'} pb={1}  onClick={() => navigate('/account-settings')}>
          <Typography fontWeight={400} fontSize={'16px'}>
            {t('Account Settings')}
          </Typography>
          <IconButton sx={{paddingRight: 0}}>
            <ChevronRightIcon />
          </IconButton>
        </Box>
      </Box>

      <Box px={'16px'}>
        <Box display={'flex'} flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'} pb={1}  onClick={() => {
            if (window.confirm(t("Are you sure you want to logout?"))) {
              logout();
              navigate('/login')}
            }
          }>
          <Typography fontWeight={400} fontSize={'16px'}>
            {t('Log Out')}
          </Typography>
          <IconButton sx={{paddingRight: 0}}>
            <ChevronRightIcon />
          </IconButton>
        </Box>
      </Box>

      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Typography variant='body2'>Version: {APP_VERSION}</Typography>
      </Box>
      
      </Container>
  )
}
