import { FormControl, InputLabel, OutlinedInput } from '@mui/material';
import React from 'react';
import styled from 'styled-components';

interface FormInputProps {
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  id: string;
  label: string;
  placeholder?: string;
}

const FormInput: React.FC<FormInputProps> = ({ value, onChange, id, label, placeholder }) => (
  <StyledFormControl variant="outlined">
    <StyledInputLabel htmlFor={id}>{label}</StyledInputLabel>
    <StyledOutlinedInput
      value={value}
      onChange={onChange}
      id={id}
      label={label}
      placeholder={placeholder}
    />
  </StyledFormControl>
);

export default FormInput;

const StyledFormControl = styled(FormControl)`
  width: 100%;
  padding-left: 16px;
  padding-right: 16px;
`;

const StyledInputLabel = styled(InputLabel)`
  font-weight: 400;
  color: #949494;
  font-size: 14px;
  padding-left: 22px;
  padding-right: 22px;
`;

const StyledOutlinedInput = styled(OutlinedInput)`
  background: white;
  border-radius: 11px;
  font-size: 14px;

  & .MuiInputBase-input {
    padding: 14px 16px;
  }
`;