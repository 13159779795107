import React, { createContext, useContext, useState } from 'react';
import GlobalSnackbar from './GlobalSnackbar';
import { AlertProps } from '@mui/material/Alert';
import { useTranslation } from "react-i18next";

interface SnackbarContextType {
  showMessage: (message: string, severity: AlertProps['severity'], autoComplete: number) => void;
}
interface SnackbarProviderProps {
  children: React.ReactNode;
}

const SnackbarContext = createContext<SnackbarContextType | undefined>(undefined);

export const SnackbarProvider: React.FC<SnackbarProviderProps> = ({ children }) => {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<AlertProps['severity']>('info');
  const [snackBarDuration,setSnackBarDuration] = useState(3000);
  const { t } = useTranslation();

  const showMessage = (message: string, severity: AlertProps['severity'] = 'info',autoHideDuration: number) => {
    setSnackbarMessage(t(message));
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
    setSnackBarDuration(autoHideDuration);
  };

  const handleClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <SnackbarContext.Provider value={{ showMessage }}>
      {children}
      <GlobalSnackbar
        open={snackbarOpen}
        severity={snackbarSeverity}
        message={snackbarMessage}
        onClose={handleClose}
        autoHideDuration={snackBarDuration}
      />
    </SnackbarContext.Provider>
  );
};

export const useSnackbar = () => {
  const context = useContext(SnackbarContext);
  if (!context) {
    throw new Error('useSnackbar must be used within a SnackbarProvider');
  }
  return context;
};