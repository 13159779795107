import Person from '@mui/icons-material/Person';
import KeyboardArrowLeft  from '@mui/icons-material/KeyboardArrowLeft';
import { Container, Box, IconButton, Typography, FormControl, InputAdornment, InputLabel, OutlinedInput, Button } from '@mui/material'
import { useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Work from '@mui/icons-material/Work';
import { useAuth } from '../context/AuthContext';
import Edit from '@mui/icons-material/Edit';
import { authenticatedFetch } from '../api/utils';
import { useSnackbar } from '../components/SnackbarProvider';
import { USERS_API_ENDPOINT } from '../utils/api';

export default function AccountSettings() {
  
  const [password, setPassword] = useState('');
  const { t, i18n } = useTranslation()
  const navigate = useNavigate();
  const {authData, setAuthData} = useAuth();
  const [name, setName] = useState(authData?.user.full_name == '' ? '' : authData?.user.full_name);
  const [position, setPosition] = useState(authData?.user.position == '' ? '' : authData?.user.position);
  const { showMessage } = useSnackbar();

  const handleConfirm = async () => {
    const response = await authenticatedFetch(`${USERS_API_ENDPOINT}${authData?.user.pk}/`, {
      full_name: name,
      position: position,
    }, 'PATCH');

    if (!response || !response.ok) {
      showMessage(response, "error", 4000);
      return;
    }
    const updatedUserData = await response.json();
    const updatedAuthData = {
      ...authData,
      user: {
        ...authData?.user,
        full_name: updatedUserData.full_name,
        position: updatedUserData.position,
      },
    };

    if (authData?.access && authData.refresh) {
      updatedAuthData.access = authData.access;
      updatedAuthData.refresh = authData.refresh;
    }

    setAuthData(updatedAuthData);
    showMessage('User updated successfully', "success", 4000);
  };

  return (
    <Container disableGutters sx={{height: '100vh', overflowY: 'scroll', pb: 8}}>
    {/* Header Box */}
    <Box display={'flex'} flexDirection={'row'} p={'16px'} bgcolor={'white'} width={'100vw'} maxWidth={'100%'} justifyContent={'space-between'} alignItems={'center'}>
          <IconButton onClick={() => navigate('/landing')}>
              <KeyboardArrowLeft />
          </IconButton>
        <Typography fontSize={'16px'} fontWeight={500}>
            {t('Account Settings')}
        </Typography>
        <Box width={'30px'}></Box>
    </Box>

    <Box p={'16px'} display={'flex'} flexDirection={'column'} alignItems={'center'}>

      {/* <Avatar sx={{ width: 120, height: 120, mb: 2 }}>TT</Avatar> */}

      <FormControl sx={{ my: 1, mt: 2 }} variant="outlined" fullWidth>
        <InputLabel sx={{fontWeight: 400, color: '#949494'}} htmlFor="outlined-adornment-name">{t('Name')}</InputLabel>
        <OutlinedInput
          id="outlined-adornment-name"
          value={name} 
          onChange={(e) => setName(e.target.value)}
          sx={{background: 'white', borderRadius: '12px'}}
          endAdornment={
            <InputAdornment position="end">
              <IconButton disabled sx={{
                  "&.Mui-disabled": {
                      color: "#949494"
                  }
              }} edge='end' disableRipple={true} disableFocusRipple={true} disableTouchRipple={true}>
                  <Person />
              </IconButton>
            </InputAdornment>
          }
          label={t("Name")}
        />
      </FormControl>

      <FormControl sx={{ my: 1, mt: 2 }} variant="outlined" fullWidth>
        <InputLabel sx={{ fontWeight: 400, color: '#949494' }} htmlFor="outlined-adornment-position">{t('Position')}</InputLabel>
        <OutlinedInput
          id="outlined-adornment-position"
          value={position} 
          onChange={(e) => setPosition(e.target.value)}
          sx={{background: 'white', borderRadius: '12px'}}
          endAdornment={
            <InputAdornment position="end">
              <IconButton disabled sx={{
                  "&.Mui-disabled": {
                      color: "#949494"
                  }
              }} edge='end' disableRipple={true} disableFocusRipple={true} disableTouchRipple={true}>
                  <Work />
              </IconButton>
            </InputAdornment>
          }
          label={t("Username")}
        />
      </FormControl>
      <Button variant="outlined" fullWidth
       onClick={() => navigate('/change-password')}
      sx={{height:'56px' , borderRadius:"12px" ,textTransform: "none", fontSize:"16px" , marginTop:"16px"}}
      >
        {t("Change Password")}
      </Button>

    </Box>

    <Box position={'absolute'} bottom={'5vh'} left={'50%'} sx={{transform: 'translateX(-50%)', width: '100%'}} px={'16px'}>
      <Button onClick={handleConfirm} variant="contained" sx={{textTransform: 'none', py: 2, borderRadius: 150, width: '100%'}}>
        <Typography fontSize={16} fontWeight={400} color='white' letterSpacing={'0.96px'}>
          {t('Confirm')}
        </Typography>
      </Button>
    </Box>  

    </Container>
  )
}
