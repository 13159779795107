import { Box, Button, Stack, TextField, Tooltip, Typography } from '@mui/material';

import React from 'react';
import qr from '../assets/img/QR.png';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

interface QRPreviewProps {
  shortName: string | null;
  t: (key: string) => string;
  handleModalOpen: () => void;
}

const QRPreview: React.FC<QRPreviewProps> = ({ shortName, t, handleModalOpen }) => {
  return (
    <>
      <Box sx={{ px: '16px', py: '20px', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        <Typography sx={{ fontWeight: 600, fontSize: 16, color: '#242424' }}>
          {t('QR Code Preview')}
        </Typography>
        <Tooltip title={t('This is where you can find more information about QR codes')} placement="left">
        <Button onClick={handleModalOpen} variant='text' sx={{ textTransform: 'none', p: 0, m: 0 }}>
        <InfoOutlinedIcon fontSize='small' sx={{color: '#4579FF' , marginRight:"8px"}}/>
          <Typography sx={{ fontWeight: 600, fontSize: 14, color: '#4579FF' }}>
            {t('Know More')}
          </Typography>
        </Button>
        </Tooltip>
      </Box>
      <Stack direction="row" spacing={2} px={'16px'}>
        <Box>
          <Box bgcolor={'white'} borderRadius={'7.605px'} textAlign={'center'} p={"12px"} display={"flex"} flexDirection={"column"} alignItems={"center"}>
          <TextField
              value={shortName ? '203' : t('Shelf Number')}
              inputProps={{ style: { textAlign: 'center',fontSize: '16px', padding:"0" } }}
              InputProps={{
                readOnly: true,
                sx: {
                  textAlign: 'center',
                  fontSize: '16px',
                  fontFamily: 'NotoSerifTibetan',
                  fontWeight: '400'
                }
              }}
              sx={{
                "& fieldset": { border: 'none' }
              }} 
            />
            <Box marginBottom={"34px"} marginTop={"12px"}>
            <img src={qr} width={'116.3px'} height={'116px'} alt="QR Code"/>
            </Box>
          </Box>
          <Typography
            sx={{
              fontWeight: '400',
              fontSize: '14px',
              textAlign: 'center',
              color: '#BBBAB8',
              mt: 1,
            }}
          >
            {t("Shelf QR Code")}
          </Typography>
        </Box>
        <Box>
          <Box bgcolor={'white'} borderRadius={'7.605px'} textAlign={'center'} p={"12px"} display={"flex"} flexDirection={"column"} alignItems={"center"}>
          <TextField
              value={shortName ? t(shortName) : t('Short Name')}
              inputProps={{ style: { textAlign: 'center',fontSize: '16px', padding:"0" } }}
              InputProps={{
                readOnly: true,
                sx: { textAlign: 'center', fontSize: '16px', fontWeight: '400', color: '#949494', fontFamily:'NotoSerifTibetan'}
              }}
              sx={{
                "& fieldset": { border: 'none' },
              }} 
            />
            <Box marginTop={"12px"}>
            <img src={qr} width={'116.3px'} height={'116px'} alt="QR Code" />
            </Box>
            <TextField
            value={shortName ? '016' : t('Accession Code')}
            inputProps={{ style: { textAlign: 'center', paddingTop:"12px", paddingBottom:"0" } }}
            InputProps={{
              readOnly: true,
              sx: {
                textAlign: 'center',
                fontSize: '16px',
                fontWeight: '400',
                fontFamily: 'NotoSerifTibetan'
              }
            }}
            sx={{
              "& fieldset": { border: 'none' },
            }} 
            />
          </Box>
          <Typography
            sx={{
              fontWeight: '400',
              fontSize: '14px',
              textAlign: 'center',
              color: '#BBBAB8',
              mt: 1,
            }}
          >
            {t("Accession QR Code")}
          </Typography>
        </Box>
      </Stack>
    </>
  );
};

export default QRPreview;
