import {
  INSTANCE_COMMENTS
} from '../utils/api';

import { AuthenticatedApiHook, InstanceComment } from '../types';
import { backendTimeToReadable } from '../utils/func';

export async function getInstanceComments(authenticatedApiHook: AuthenticatedApiHook, instanceId: string): Promise<InstanceComment[]> {
  let url = `${INSTANCE_COMMENTS}`;
  const params = new URLSearchParams();
  params.append('instance', instanceId);
  url += `?${params.toString()}`;

  const response = await authenticatedApiHook(url);
  if (response) {
    const data = await response.json();
    data.forEach((item: InstanceComment) => {
      if (item.created_at) {
        item.created_at = backendTimeToReadable(item.created_at);
      }
    });
    return data;
  }
  return [];
}

export async function addInstanceComments(authenticatedApiHook: AuthenticatedApiHook, instanceId: string, comment: string) {
  let url = `${INSTANCE_COMMENTS}`;

  const response = await authenticatedApiHook(url, "POST", undefined, {
    instance: instanceId,
    comment: comment,
  });
  if (response) {
    return await response.json();
  } else {
    // TODO: add error handling
  }
}
