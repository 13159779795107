import {
  createBrowserRouter,
} from "react-router-dom";
import Login from "../screens/Login";
import Landing from "../screens/Landing";
import LanguageScreen from "../screens/LanguageScreen";
import Welcome from "../screens/Welcome";
import AddItem from "../screens/AddItemV2";
import LibraryInfo from "../screens/LibraryInfo";
import ChangePassword from "../screens/ChangePassword";
import AccountSettings from "../screens/AccountSettings";
import LanguageSettings from "../screens/LanguageSettings";
import AddUser from "../screens/AddUser";
import ViewInstance from "../screens/ViewInstance";
import AssociatedLibrarians from "../screens/AssociatedLibrarians";
import CataloguedItems from "../screens/CataloguedItems";
import ErrorScreen from "../components/ErrorScreen";
import EditItem from "../screens/EditItem";
import SearchScannerScreen from "../screens/SearchScannerScreen";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <Welcome />,
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/landing",
    element: <Landing />,
  },
  {
    path: "/language-screen",
    element: <LanguageScreen />,
  },
  {
    path: "/language-settings",
    element: <LanguageSettings />,
  },
  {
    path: "/welcome",
    element: <Welcome />,
  },
  {
    path: "/add-item",
    element: <AddItem />,
  },
  {
    path: "/edit-item/:instanceId",
    element: <EditItem />,
  },
  {
    path: "/view-instance/:instanceId",
    element: <ViewInstance />,
  },
  {
    path: "/scan",
    element: <SearchScannerScreen />,
  },
  {
    path: "/library-info",
    element: <LibraryInfo />,
    
  },
  {
    path: "/change-password",
    element: <ChangePassword />
  },
  {
    path: "/account-settings",
    element: <AccountSettings />
  },
  {
    path: "/add-user",
    element: <AddUser />
  },
  {
    path: "/associates",
    element: <AssociatedLibrarians />
  },
  {
    path: "/my-items",
    element: <CataloguedItems />
  },
  {
    path: "/*",
    element:<ErrorScreen/>
  }

]);