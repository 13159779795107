import * as React from 'react';

import { Box, Typography } from '@mui/material';

import { Instance } from '../types';
import styled from '@emotion/styled';
import { useNavigate } from 'react-router-dom';
import boook from '../assets/img/StakedBook.png';

interface LibraryBookProps {
  bookData: Instance;
  archived?: boolean ;
}

const LibraryBook: React.FC<LibraryBookProps> = ({ bookData,archived=  false }) => {
  const navigate = useNavigate();
  const name = bookData.title || 'Unknown Book';
  const authorText = bookData.author ? `${bookData.author}` : 'Unknown Author';
  const yearText =  bookData.published_year ? `${bookData.published_year}` : '';

  const handleNavigate = () => {
      navigate(`/view-instance/${bookData.id}`, {
        state: { archived }
      });
  };

  return (
    <StyledContainer onClick={handleNavigate}>
      <StyledBox>
        <StyledImage src={bookData.front_cover} alt={name} />
        <StyledInfo>
          <StyledTitle>{bookData.title}</StyledTitle>
          <StyledAuthor>{authorText}{yearText ? `, ${yearText}` : ''}</StyledAuthor>
        </StyledInfo>
      </StyledBox>
      <Box sx={{backgroundColor: '#FDE8C6', borderRadius: '10px', flexDirection: 'row', display: 'flex', alignSelf: 'center', paddingInline: '8px',  paddingY: '2px'}}>
        <img src={boook} height={18} width={18} style={{paddingRight: 3, marginTop: 2}}/>
        <Typography color={'#FFA100'} fontSize={14}>{bookData.item_count}</Typography>
      </Box>
    </StyledContainer>
  );
};

export default LibraryBook;

const StyledContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  padding-bottom: 12px;
  cursor: pointer;
  justify-content: space-between;
`;

const StyledBox = styled(Box)`
  display: flex;
  flex-direction: row;
`;

const StyledImage = styled.img`
  height: 75px;
  width: 56.46px;
`;

const StyledInfo = styled(Box)`
  padding-left: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`;

const StyledTitle = styled(Typography)`
  font-size: 16px;
  padding-bottom: 4px;
  font-weight: 500;
`;

const StyledAuthor = styled(Typography)`
  font-size: 14px;
  color: #908056;
  font-weight: 500;
`;
