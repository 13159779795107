import { createContext, useContext, useState, ReactNode, useEffect } from 'react';

interface AuthData {
  access: string;
  refresh: string;
  user: {
    pk: number;
    username: string;
    full_name: string;
    role: 'lsa' | 'la' | 'lu';  // Library Super Admin, Library Admin, Library User
    has_library: boolean;
    position?: string;
  };
}

interface AuthContextType {
  authData: AuthData | null;
  setAuthData: (data: AuthData | null) => void;
  isLoggedIn: boolean;
  login: (data: AuthData) => void;
  logout: () => void;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export function AuthProvider({ children }: { children: ReactNode }) {
  const [authData, setAuthData] = useState<AuthData | null>(() => {
    // Retrieve stored data from localStorage on mount
    try {
      const data = localStorage.getItem('authData');
      return data ? JSON.parse(data) : null;
    } catch (error) {
      console.error('Error parsing AuthData from localStorage:', error);
      return null;
    }
  });

  const [isLoggedIn, setIsLoggedIn] = useState(!!authData);  // Update isLoggedIn based on authData

  useEffect(() => {
    // Persist authData and update isLoggedIn on changes
    localStorage.setItem('authData', JSON.stringify(authData));
    setIsLoggedIn(!!authData);
  }, [authData]);

  const login = (data: AuthData) => {
    setAuthData(data);
  };

  const logout = () => {
    setAuthData(null);
    localStorage.removeItem('authData')
    localStorage.removeItem('bottomNavValue')
  };

  return (
    <AuthContext.Provider value={{ authData, setAuthData, isLoggedIn, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth(): AuthContextType {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
}
