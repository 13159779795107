import React from "react";
import {
  IconButton,
  InputAdornment,
  OutlinedInput,
  FormControl,
  InputLabel,
  Button,
  Box
} from "@mui/material";
import { Scanner } from '@yudiel/react-qr-scanner';
import QrCodeScannerOutlinedIcon from "@mui/icons-material/QrCodeScannerOutlined";
import { useSnackbar } from "./SnackbarProvider";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

interface QRCodeScannerProps {
  value: string;
  onChange: (newValue: string) => void;
  label: string;
  id: string;
  required?: boolean;
  qrEnabled?: boolean;
  setQREnabled?: (enabled: boolean) => void;
  autofocus?: boolean;
}

const QRCodeScanner: React.FC<QRCodeScannerProps> = ({
  value,
  onChange,
  label,
  id,
  required,
  qrEnabled,
  setQREnabled,
  autofocus = false
}) => {
  const [openScanner, setOpenScanner] = React.useState(false);
  const [scannerReady, setScannerReady] = React.useState(false);
  const { showMessage } = useSnackbar();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // Adjust based on your needs

  const onDecode = (result: string) => {
    result = result.split("/").pop() || result;
    onChange(result);
    setOpenScanner(false);
    setScannerReady(false);
    setQREnabled && setQREnabled(true);
  };

  const handleOpenScanner = async () => {
    try {
      const permissionStatus = await navigator.permissions.query({ name: "camera" as PermissionName });
      if (permissionStatus.state === "granted" || permissionStatus.state === "prompt") {
        setOpenScanner(true);
        setScannerReady(false);
        setTimeout(() => setScannerReady(true), 1000); // Delay to ensure scanner is ready
        setQREnabled && setQREnabled(false);
      } else {
        showMessage("Camera permission is denied. Please allow camera access in your browser settings.", "error", 4000);
      }
    } catch (error) {
      showMessage("An error occurred while checking camera permissions.", "error", 4000);
    }
  };

  return (
    <FormControl sx={{ my: 1, mt: 2 }} variant="outlined" fullWidth required={required}>
      <InputLabel sx={{ fontWeight: 400, color: "#949494" }} htmlFor={id}>
        {label}
      </InputLabel>
      <OutlinedInput
        id={id}
        sx={{ background: "white", borderRadius: "12px" }}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        label={label}
        autoFocus={autofocus}
        disabled={!qrEnabled}
        inputMode={id === 'isbn_input_qr' ? 'numeric' : 'text'}
        endAdornment={
          <InputAdornment position="end">
            <IconButton onClick={handleOpenScanner} disabled={!qrEnabled}>
              <QrCodeScannerOutlinedIcon />
            </IconButton>
          </InputAdornment>
        }
      />
      {openScanner && scannerReady && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            mt: 2,
            gap: 2,
            width: isMobile ? '90vw' : '400px',
            maxHeight: '90vh',
            minHeight: '320px',
          }}
        >
          <Scanner
            onScan={(result) => onDecode(result[0].rawValue)}
            formats={["ean_13", "qr_code"]}
            scanDelay={100}
            components={{ zoom: true, torch: true }}
          />
          <Button
            onClick={() => {
              setOpenScanner(false);
              setScannerReady(false);
              setQREnabled && setQREnabled(true);
            }}
          >
            Close Scanner
          </Button>
        </Box>
      )}
    </FormControl>
  );
};

export default QRCodeScanner;
