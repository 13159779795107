import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Container,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
  Snackbar,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { AuthorOptions, CollectionOptions, ItemData } from "../types";
import React, { useCallback, useEffect, useState } from "react";
import {
  addOrUpdateItem,
  createItemAndInstance,
  getCollections,
  getItemByCollectionAndVolume,
  getPublisherData,
  getPublishingPlacesData,
  searchPersons,
} from "../api/items";
import { useAuthenticatedRequest } from "../hooks/AuthenticatedApiCallHook";
import ImageUpload from "../components/ImageUpload";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import SearchIcon from '@mui/icons-material/Search';
import QRCodeScanner from "../components/QRCodeScanner";
import { debounce } from "../utils/func";
import { scanningStatusMap } from "../utils/constants";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "../components/SnackbarProvider";
import { useTranslation } from "react-i18next";
import useGenericMutation from "../hooks/MutationHook";
import InstanceSelector from "../components/InstanceSelector";

export default function AddItem() {
  const [disableInstanceEdit, setDisableInstanceEdit] = React.useState(false);
  const [qrEnabled, setQREnabled] = React.useState(true);
  const [shelfCode, setShelfCode] = React.useState("");
  const [accessionCode, setAccessionCode] = React.useState("");
  const [review, setReview] = React.useState(false)
  const [open, setOpen] = React.useState(false);
  const [itemData, setItemData] = React.useState<ItemData>({
    id: null,
    isbn: "",
    title: "",
    author: "",
    author_name: "",
    edition: "",
    front_cover: "",
    back_cover: "",
    random_page: "",
    condition: "Good",
    notes: "",
    publisher: "",
    collection: "",
    published_year: "",
    place_of_publication: "",
    instance: null,
    accession_code: "",
    temporary_shelf_number: "",
    page_count: "",
    is_multi_volume: "false",
    review: false,
    restricted_access: false,
    item_type: "Book",
    front_cover_full: "",
    scanning_status: "",
    source: ""
  });
  const [imageEncodedString, setImageEncodedString] = useState({
    front_cover: "",
    back_cover: "",
    random_page: "",
  });
  const [authorOptions, setAuthorOptions] = useState<AuthorOptions[]>([]);
  const [temporaryAccessionCode,setTemporaryAccessionCode] = useState("");
  const [source, setSource] = useState("");
  const [collectionOptions, setCollectionOptions] = useState<
  CollectionOptions[]
  >([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [publishersName, setPublishersName] = useState([]);
  const [placesOfPublicationName, setPlacesOfPublicationName] = useState([])
  const [isBdrcLoading, setIsBdrcLoading] = useState<boolean | undefined>(false);
  const [isSaveLoading, setIsSaveLoading] = useState<boolean | undefined>(false);
  const [instanceSearchType, setInstanceSearchType] = useState<'isbn' | 'title'>('isbn');
  const [showInstanceSelector, setShowInstanceSelector] = useState(false);
  const { mutate } = useGenericMutation();

  const { showMessage } = useSnackbar();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const authenticatedApiHook = useAuthenticatedRequest();

  const selectFetchedInstance = (item: ItemData) => {
    const { ...filteredData } = item;
    if (filteredData.collection) {
      filteredData.is_multi_volume = "true";
      filteredData.id = null;
      filteredData.volume_number = ""; 
      filteredData.front_cover = "";
      filteredData.front_cover_full = "";
      filteredData.back_cover = "";
      filteredData.random_page = ""
    }
    document.getElementById("text-volume_number")?.focus();
    setItemData(filteredData);
    setImageEncodedString(filteredData);
  }

  const fetchByCollectionAndVolume = async () => {
    if (itemData.collection && itemData.volume_number) {
      const { data } = await getItemByCollectionAndVolume(
        String(itemData.collection), itemData.volume_number);
      
      if (data && data.length > 0) {
        const itemData = data[0];
        const { page_count, ...filteredData } = itemData;
        filteredData.is_multi_volume = "true";
        
        setItemData(filteredData);
        setImageEncodedString(itemData);
      }
    }
  };

  useEffect(() => {
    if (itemData.id) {
      showMessage(t("To edit fields, please save item and edit."), "info", 4000);
      setDisableInstanceEdit(true);
    } else {
      setDisableInstanceEdit(false);
    }
  }, [itemData.id]);

  const handleChangeDropDown = async (value:string, type:string,setData: any) => {
    const trimmedValue = value.trim();
    if (trimmedValue !== '') {
      setIsBdrcLoading(true);

      try {
        let data, error;
        if(type==="publisher"){
          ({ data, error } = await getPublisherData(trimmedValue));
        }
        else if (type==="publishing_place"){
          ({ data, error } = await getPublishingPlacesData(trimmedValue));
        }
        if (error) {
          showMessage(error || "Unknown error", "error", 4000);
        } else {
          if (data && data.count > 0) {
            setData(data.data);
          } else {
            setData([]);
          }
        }
      } catch (error) {
        showMessage('Error fetching data', "error", 4000);
      } finally {
        setIsBdrcLoading(false);
      }
    } else {
      setData([]);
    }
  };
  
  const debouncedHandleChange = useCallback(debounce(handleChangeDropDown, 1000), []);
  
  const handleChangePublisher = (value: string) => {
    debouncedHandleChange(value, "publisher",setPublishersName);
  };
  
  const handleChangePlaceOfPublication = (value: string) => {
    debouncedHandleChange(value, "publishing_place",setPlacesOfPublicationName);
  };
  

  const handleChange = (name: string, value: any) => {
    if (name === "condition") {
      value = value ? "Valuable" : "";
    }
    if(name === "isbn"){
      if(!isNaN(value) && !isNaN(parseInt(value))){
        setItemData({ ...itemData, [name]: value });
      }
      else {
        setErrorMessage('Invalid ISBN');
        setOpen(true);
        setItemData({ ...itemData, [name]: "" });
        return;
      }
    }
    if(name === "page_count"){
      if(!isNaN(value) && !isNaN(parseInt(value))){
        setItemData({ ...itemData, [name]: value });
      }
      else {
        setErrorMessage('Invalid Page Count');
        setOpen(true);
        setItemData({ ...itemData, [name]: "" });
        return;
      }
    }
    if(name === "published_year"){
      if(!isNaN(value) && !isNaN(parseInt(value))){
        setItemData({ ...itemData, [name]: value });
      }
      else {
        setErrorMessage('Invalid Year');
        setOpen(true);
        setItemData({ ...itemData, [name]: "" });
        return;
      }
    }
    
    if(name === "accession_input_qr"){
      if (/^\d*$/.test(value)) {
        setAccessionCode(value)
      } else {
        setErrorMessage('Only numerals are allowed');
        setOpen(true);
        setAccessionCode("");
        return;
      }
    }
    if(name === "shelf_input_qr"){
      if (/^[a-zA-Z0-9\u0F00-\u0FFF]*$/.test(value)) {
        setShelfCode(value);
      }else{
        setErrorMessage('Only alpha numeric are allowed');
        setOpen(true);
        setShelfCode("");
        return;
      } 
    }
    if (name === "temporary_accession_code") {
      if (/^[a-zA-Z0-9\u0F00-\u0FFF\s]*$/.test(value)) {
        setTemporaryAccessionCode(value);
      } else {
          setErrorMessage('Only alphanumeric characters are allowed');
          setOpen(true);
          setItemData({ ...itemData, [name]: "" });
          return;
      }
  }
    setItemData({ ...itemData, [name]: value });
  };

  const handleClose = (_event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const handleImageChange = (file: File | null, field: string) => {
    console.log(field);
    if (!file) {
      return;
    }

    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = (event) => {
        if (typeof event.target?.result === "string") {
          const mimeType = event.target.result.split(",")[0].split(":")[1];
          // @ts-ignore
          setImageEncodedString((prevState) => ({
            ...prevState,
            [field]: `data:${mimeType};base64,${
              // @ts-ignore
              event?.target?.result?.split(",")[1]
              }`,
          }));
          setItemData((prevState) => ({ ...prevState, [field]: file }));
        }
      };
    }
  };

  const fetchAuthors = async (query: string) => {
    if (!query) return;

    setIsBdrcLoading(true);

    try {
      const authors = await searchPersons(query);
      const _authorOptions = authors.map((author: any) => ({
        id: author.id,
        bdrc_id: author.bdrc_id,
        name: author.name,
        display_name: author.birth ? `${author.name} (${author.birth})` : author.name,
      }));
      setAuthorOptions(_authorOptions || []);
    } catch (error) {
      showMessage('Error fetching authors', "error", 2000);
      setAuthorOptions([]);
    } finally {
      setIsBdrcLoading(false);
    }
  };

  const debouncedFetchAuthors = useCallback(debounce(fetchAuthors, 1000), []);

  const searchCollections = async (query: string) => {
    if (!query) return;
    const collections = await getCollections(query);
    if (collections) {
      setCollectionOptions(collections);
    }
  };

  const hasErrors = () => {
    const currentYear = new Date().getFullYear();
  
    if (itemData.hasOwnProperty("published_year") && !!itemData.published_year) {
      let year = parseInt(itemData["published_year"], 10);
  
      if (isNaN(year) || year < 800 || year > currentYear) {
        console.log("year", year, currentYear);
        showMessage("Year is invalid", "error", 4000);
        setItemData(prevData => ({ ...prevData, ["published_year"]: "" }));
        return true;
      }
    }
    return false;
  };

  const handleSubmit = async () => {
    if (hasErrors()) {
      setIsSaveLoading(false);
      return;
    }
    itemData["accession_code"] = accessionCode;
    itemData["temporary_shelf_number"] = shelfCode;
    itemData["review"] = review;
    itemData["temporary_accession_code"] = temporaryAccessionCode;
    itemData["source"] = source;
  
    let item;
    setIsSaveLoading(true);
  
    try {
      if (itemData.id) {
        console.log("Adding copy to existing item");
        item = await addOrUpdateItem(authenticatedApiHook,{
          temporary_shelf_number: itemData.temporary_shelf_number,
          accession_code: itemData.accession_code,
          condition: itemData.condition,
          instance: itemData.id,
          temporary_accession_code: itemData.temporary_accession_code,
          source: itemData.source,
        });
      } else {
        console.log("Creating new item");
        item = await createItemAndInstance(authenticatedApiHook,itemData);
      }  

      if (!item) {
        throw new Error("Creation failed");
      }
  
      showMessage("Item created", "success", 4000);
      navigate(`/view-instance/${item.instance}`);
      // navigate("/");
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setIsSaveLoading(false);
    }
  };
  

  const isSaveDisabled = (): [label: string, elementId: string] | null => {
    if (!shelfCode) {
      return ["Shelf QR Code", "shelf_input_qr"];
    }
    if (!accessionCode) {
      return ["Accession Code", "accession_input_qr"];
    }
    if (itemData.collection && !itemData.volume_number) {
      return ["Volume Number", "text-volume_number"];
    }
    if (!itemData.item_type) {
      return ["Item Type", "item_type"];
    }
    if (!itemData.title) {
      return ["Title", "title"];
    }
    if (!itemData.front_cover) {
      return ["Front Cover", "front_cover"];
    }
    if (itemData.is_multi_volume === "true" && !itemData.collection_name) {
      return ["Volume", "collection_search"];
    }
    return null;
  };

  const handleClickSave = () => {
    if (isSaveDisabled()) {
      handleFocus();
    } else {
      mutate({
        mutationFn: handleSubmit,
        queryKeys: ['fetchInstances', 'fetchBookCount']
      });
    }
  };

  const handleFocus = () => {
    let _fieldName = isSaveDisabled()?.[1]
    let _fieldLabel = isSaveDisabled()?.[0] || 'all required fields'

    if(!_fieldName){
      return
    }

    showMessage(`${t('Please specify')} ${t(_fieldLabel)}`, "warning", 4000);

    if( _fieldName ==="front_cover" || _fieldName==="random_page"){
      _fieldName = "upload_"+_fieldName
    }

    const _element = document.getElementById(_fieldName);

    if (_element) {
      _element.focus();

      _element.addEventListener('mouseenter', () => {
        _element.classList.add('hover-effect'); 
      });
      
      _element.addEventListener('mouseleave', () => {
        _element.classList.remove(':hover-effect');
      });

      if(_fieldName.startsWith("upload_")){
        _element.style.border = '1px solid #6D2312';
        _element.style.backgroundColor = "rgba(109, 35, 18, 0.04)";
        setTimeout(()=>{
          _element.style.border = '1px solid #DFDFDF';
          _element.style.backgroundColor = "white";
        },2000)
      }
    }
  }

  return (
    <Container
      disableGutters
      sx={{ height: "100vh", overflowY: "scroll", pb: 8 }}
    >
      {/* Header Box */}
      <Box
        display={"flex"}
        flexDirection={"row"}
        p={"16px"}
        bgcolor={"white"}
        width={"100vw"}
        maxWidth={"100%"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <IconButton onClick={() => navigate('/landing')} style={{ padding: 0 }}>
          <KeyboardArrowLeft />
        </IconButton>
        <Typography fontSize={"16px"} fontWeight={500}>
          {t("Add new item")}
        </Typography>
        <Box width={"30px"}></Box>
      </Box>

      <Typography
        textAlign={"left"}
        fontSize={"14px"}
        fontWeight={400}
        p={"16px"}
      >
        {t("Please enter all the required(*) information")}
      </Typography>

      <Box sx={{ display: "flex", flexWrap: "wrap" }} px={"16px"} mt={2}>
        <Typography fontWeight={600}>{t("Basic Details")}</Typography>

        <QRCodeScanner
          id="shelf_input_qr"
          label={t("Shelf QR Code")}
          value={shelfCode}
          onChange={(value) => handleChange("shelf_input_qr",value)}
          required={true}
          qrEnabled={qrEnabled}
          setQREnabled={setQREnabled}
        />
        <QRCodeScanner
          id="accession_input_qr"
          label={t("Accession Code")}
          value={accessionCode}
          onChange={(value) => handleChange("accession_input_qr", value)}
          required={true}
          qrEnabled={qrEnabled}
          setQREnabled={setQREnabled}
        />
        <QRCodeScanner
          id="isbn_input_qr"
          label={t("ISBN")}
          value={itemData.isbn || ""}
          onChange={(value) => {
            handleChange("isbn", value);
            if (value.length === 13 || value.length === 10) {
              setShowInstanceSelector(true);
              setInstanceSearchType('isbn');
            }
          }}
          required={false}
          qrEnabled={qrEnabled}
          setQREnabled={setQREnabled}
        />

        <Divider sx={{ mt: 2, width: '100%' }} />

        <Typography sx={{ mt: 2 }} fontWeight={600}>
          {t("Item Details")}
        </Typography>
        
        <FormControl sx={{my: 1, mt: 2}} fullWidth id="item_type">
          <Typography sx={{ mt: 1 }} fontWeight={400}>
            {t("Item Type")} *
          </Typography>
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            sx={{justifyContent: 'space-between'}}
            onChange={(e) => handleChange("item_type", e.target.value)}
            value={itemData.item_type || ""}
          >
            <FormControlLabel disabled={disableInstanceEdit} value="BOOK" control={<Radio />} label={t("Book")} />
            <FormControlLabel disabled={disableInstanceEdit} value="MANUSCRIPT" control={<Radio />} label={t("Manuscript")} />
            <FormControlLabel disabled={disableInstanceEdit} value="WOODBLOCK" control={<Radio />} label={t("Woodblock")} />
            <FormControlLabel disabled={disableInstanceEdit} value="MODERN_PUBLICATION" control={<Radio />} label={t("Modern Publication")} />
          </RadioGroup>
        </FormControl>

          <Grid container sx={{alignItems: 'center'}}>
            <Grid item xs>
              <FormControl sx={{ my: 1, mt: 2 }} variant="outlined" fullWidth>
                <InputLabel
                  sx={{ fontWeight: 400, color: "#949494" }}
                  htmlFor="outlined-adornment-password"
                >
                  {t("Title")} *
                </InputLabel>
                <OutlinedInput
                id="outlined-adornment-password"
                sx={{ background: "white", borderRadius: "12px" }}
                value={itemData.title}
                onChange={(e) => handleChange(e.target.name, e.target.value)}
                name="title"
                label={t("Title")}
                disabled={disableInstanceEdit}
                />
              </FormControl>
            </Grid>
            <Grid item xs={2}>
              <Button onClick={() => {
                setShowInstanceSelector(true);
                setInstanceSearchType('title');
              }}>
                <SearchIcon />
              </Button>
            </Grid>
          </Grid>

        <FormControl
          sx={{ my: 1, mt: 2, borderRadius: "12px" }}
          variant="outlined"
          fullWidth
        >
          <Autocomplete
            freeSolo
            id="author-search"
            disableClearable
            sx={{ borderRadius: "12px" }}
            loading={isBdrcLoading}
            loadingText={`${t("Loading")}...`}
            noOptionsText={"No results found"} // will work by removing freeSolo
            onChange={(_event, _newValue, _reason, details) => {
              const selectedAuthor = details?.option;
              if (selectedAuthor?.id) {
                setItemData({ ...itemData, author: selectedAuthor.id });
              }
            }}
            options={authorOptions}
            getOptionLabel={(option) =>
              (option as AuthorOptions).name || option.toString()
            }
            onInputChange={(_event, newInputValue) => {
              if (newInputValue.length === 0) {
                setItemData({ ...itemData, author: "", author_name: "" });
                return;
              }
              setItemData({ ...itemData, author_name: newInputValue });
              if (newInputValue.length > 2) {
                setIsBdrcLoading(true);
                debouncedFetchAuthors(newInputValue);
              }
            }}
            value={String(itemData.author_name || itemData.author || "")}
            renderInput={(params) => (
              <TextField
                {...params}
                sx={{ background: "white", borderRadius: "12px" }}
                label={t("Author")}
                InputProps={{
                  ...params.InputProps,
                  type: "search",
                  style: { borderRadius: "12px" },
                }}
              />
            )}
            disabled={disableInstanceEdit}
          />
        </FormControl>

        <Box
          display={"flex"}
          flexDirection={"row"}
          flex={1}
          justifyContent={"space-between"}
          my={2}
        >
          <ImageUpload
            key={Math.random()} // adding this because if i cancel the crop without any change and upload same image this component doesn't gets called
            image={imageEncodedString.front_cover}
            fullImage={itemData.front_cover_full}
            onImageChange={(e) => handleImageChange(e, "front_cover")}
            onRemoveImage={() => {
              setItemData((prevState) => ({ ...prevState, front_cover: "" }));
              setImageEncodedString((prevState) => ({
                ...prevState,
                front_cover: "",
              }));
            }}
            label={`${t("Front Cover")} *`}
            componentID="front_cover"
            disabled={disableInstanceEdit}
          />

          <ImageUpload
            key={Math.random()}
            image={imageEncodedString.random_page}
            onImageChange={(e) => handleImageChange(e, "random_page")}
            onRemoveImage={() => {
              setItemData((prevState) => ({ ...prevState, random_page: "" }));
              setImageEncodedString((prevState) => ({
                ...prevState,
                random_page: "",
              }));
            }}
            label={t("Random Page")}
            componentID="random_page"
            disabled={disableInstanceEdit}
          />

          <ImageUpload
            key={Math.random()}
            image={imageEncodedString.back_cover}
            onImageChange={(e) => handleImageChange(e, "back_cover")}
            onRemoveImage={() => {
              setItemData((prevState) => ({ ...prevState, back_cover: "" }));
              setImageEncodedString((prevState) => ({
                ...prevState,
                back_cover: "",
              }));
            }}
            label={t("Back Cover")}
            componentID="back_cover"
            disabled={disableInstanceEdit}
          />
        </Box>

        <Divider sx={{ mt: 2, width: '100%' }} />

        <Box sx={{ display: "flex", flexWrap: "wrap" }} mt={1}>
          <Typography sx={{ mt: 2, px: 1 }} fontWeight={600}>
            {t("Volume")}
          </Typography>

          <FormControl sx={{ my: 1, mt: 2, px: 1 }} variant="outlined" fullWidth>
            <FormControlLabel
              control={
                <Switch
                  checked={itemData.is_multi_volume === "true"}
                  onChange={(e) =>
                    handleChange(
                      "is_multi_volume",
                      e.target.checked ? "true" : "false"
                    )
                  }
                  name="is_multi_volume"
                  color="primary"
                  disabled={disableInstanceEdit}
                />
              }
              label={t("Is this part of a multi-volume set?")}
            />
          </FormControl>
          {itemData.is_multi_volume === "true" && (
            <>
            <FormControl sx={{ my: 1, mt: 2 }} variant="outlined" fullWidth required>
              <Autocomplete
                freeSolo
                id="collection_search"
                sx={{ background: "white", borderRadius: '12px'}}
                disableClearable
                onChange={(_event, _newValue, _reason, details) => {
                  const selectedCollection = details?.option;
                  if (selectedCollection?.id) {
                    setItemData({
                      ...itemData,
                      collection: selectedCollection.id,
                    });
                  }
                }}
                options={collectionOptions}
                getOptionLabel={(option) =>
                  (option as CollectionOptions).title || option.toString()
                }
                onInputChange={(_event, newInputValue) => {
                  setItemData({ ...itemData, collection_name: newInputValue });
                  if (newInputValue.length > 2)
                    searchCollections(newInputValue);
                }}
                value={String(itemData.collection_name || "")}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    sx={{ borderRadius: '12px'}}
                    label={t("Search for a collection")}
                    InputProps={{
                      ...params.InputProps,
                      type: "search",
                      style: { borderRadius: "12px" }
                    }}
                  />
                )}
                disabled={disableInstanceEdit}
              />
            </FormControl>

            <FormControl sx={{ my: 1, mt: 2 }} variant="outlined" fullWidth>
              <InputLabel
                sx={{ fontWeight: 400, color: "#949494" }}
                htmlFor="outlined-adornment-password"
              >
                {t("Volume Number")}
              </InputLabel>
              <OutlinedInput
                id="text-volume_number"
                sx={{ background: "white", borderRadius: "12px" }}
                value={itemData.volume_number}
                onChange={(e) => {
                  const {name,value } =e.target;
                  if (/^[a-zA-Z0-9]*$/.test(value)) {
                    setItemData((prev) => ({ ...prev, [name]: value }));
                  } else {
                    showMessage("Only alphanumeric characters are allowed", "error", 4000);
                  }
                }}
                name="volume_number"
                label={t("Volume Number")}
                disabled={disableInstanceEdit}
                onBlur={() => fetchByCollectionAndVolume()}
              />
            {disableInstanceEdit && <Button sx={{mt: 1}} variant="outlined" onClick={() => {
              setItemData({...itemData, id: null, volume_number: "", front_cover: "", back_cover: "", random_page: ""});
            }}>{t("Change Volume")}</Button>}
            </FormControl>
            </>
          )}
        </Box>

        <Divider sx={{ mt: 2, width: '100%' }} />

        <Typography sx={{ mt: 2, px: 1 }} fontWeight={600}>
          {t("Additional Details")}
        </Typography>

        <FormControl sx={{ my: 1, mt: 2 }} variant="outlined" fullWidth>
          <InputLabel
            sx={{ fontWeight: 400, color: "#949494" }}
            htmlFor="outlined-adornment-password"
          >
            {t("Edition")}
          </InputLabel>
          <OutlinedInput
            id="outlined-adornment-password"
            sx={{ background: "white", borderRadius: "12px" }}
            value={itemData.edition}
            onChange={(e) => handleChange(e.target.name, e.target.value)}
            name="edition"
            label={t("Edition")}
            disabled={disableInstanceEdit}
          />
        </FormControl>

        <FormControl sx={{ my: 1, mt: 2 }} variant="outlined" fullWidth>
          <InputLabel
            sx={{ fontWeight: 400, color: "#949494" }}
            htmlFor="outlined-adornment-password"
          >
            {t("Page count")}
          </InputLabel>
          <OutlinedInput
            id="outlined-adornment-password"
            sx={{ background: "white", borderRadius: "12px" }}
            value={itemData.page_count}
            onChange={(e) => handleChange(e.target.name, e.target.value)}
            name="page_count"
            inputMode="numeric"
            label={t("Page count")}
            disabled={disableInstanceEdit}
          />
        </FormControl>

        <FormControl sx={{ my: 1, mt: 2 }} variant="outlined" fullWidth>
          <Autocomplete
            freeSolo
            disableClearable
            loading={isBdrcLoading}
            loadingText={`${t("Loading")}...`}
            noOptionsText={"No results found"}
            options={publishersName}
            getOptionLabel={(option) => option}
            onChange={(_, value) => setItemData({ ...itemData, publisher: value || '' })}
            value={itemData.publisher} 
            onInputChange={(_event, newInputValue) => {
              const trimmedValue = newInputValue.trim();
              setItemData({ ...itemData, publisher: trimmedValue });
              if (trimmedValue.length === 0) {
                setPublishersName([]);
              }
              if (trimmedValue.length > 2) {
                setIsBdrcLoading(true);
                handleChangePublisher(trimmedValue);
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                id="outlined-adornment-password"
                sx={{ background: "white", borderRadius: "12px" }}
                label={t("Publisher")}
                name="publisher"
                disabled={disableInstanceEdit}
                InputProps={{
                  ...params.InputProps,
                  type: "search",
                  style: { borderRadius: "12px" },
                  onKeyDown: (event) => {
                    if (event.key === 'Enter') {
                      handleChangePublisher(itemData.publisher || '');
                    }
                  },
                }}
              />
              )}
          />
        </FormControl>
        <FormControl sx={{ my: 1, mt: 2 }} variant="outlined" fullWidth>
          <InputLabel
            sx={{ fontWeight: 400, color: "#949494" }}
            htmlFor="outlined-adornment-password"
          >
            {t("Year")}
          </InputLabel>
          <OutlinedInput
            id="outlined-adornment-password"
            sx={{ background: "white", borderRadius: "12px" }}
            value={itemData.published_year}
            onChange={(e) => handleChange(e.target.name, e.target.value)}
            name="published_year"
            label={t("Year")}
            disabled={disableInstanceEdit}
          />
        </FormControl>
        <FormControl sx={{ my: 1, mt: 2 }} variant="outlined" fullWidth>
        <Autocomplete
            freeSolo
            disableClearable
            loading={isBdrcLoading}
            loadingText={`${t("Loading")}...`}
            noOptionsText={"No results found"}
            options={placesOfPublicationName}
            getOptionLabel={(option) => option}
            onChange={(_, value) => setItemData({ ...itemData, place_of_publication: value || '' })}
            value={itemData.place_of_publication} 
            onInputChange={(_event, newInputValue) => {
              const trimmedValue = newInputValue.trim();
              setItemData({ ...itemData, place_of_publication: trimmedValue });
              if (trimmedValue.length === 0) {
                setPlacesOfPublicationName([]);
              }
              if (trimmedValue.length > 2) {
                setIsBdrcLoading(true);
                handleChangePlaceOfPublication(trimmedValue);
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                id="outlined-adornment-password"
                sx={{ background: "white", borderRadius: "12px" }}
                label={t("Place of publication")}
                name="place_of_publication"
                disabled={disableInstanceEdit}
                InputProps={{
                  ...params.InputProps,
                  type: "search",
                  style: { borderRadius: "12px" },
                  onKeyDown: (event) => {
                    if (event.key === 'Enter') {
                      handleChangePlaceOfPublication(itemData.place_of_publication || '');
                    }
                  },
                }}
              />
              )}
          />
        </FormControl>
        <FormControl sx={{ my: 1, mt: 2 }} variant="outlined" fullWidth>
          <InputLabel
            sx={{ fontWeight: 400, color: "#949494" }}
            htmlFor="outlined-adornment-password"
          >
            {t("Additional comment")}
          </InputLabel>
          <OutlinedInput
            sx={{ background: "white", borderRadius: "12px" }}
            value={itemData.notes}
            name="notes"
            onChange={(e) => handleChange(e.target.name, e.target.value)}
            label={t("Additional comment")}
            disabled={disableInstanceEdit}
          />
        </FormControl>

        <FormControl sx={{ mt: 2, mb: 2 }} variant="outlined" fullWidth>
          <InputLabel
            sx={{ fontWeight: 400, color: "#949494" }}
            htmlFor="outlined-status"
          >
            {t("Scanning Status")}
          </InputLabel>
          <Select
            labelId="scanning_status_select"
            id="scanning_status_select"
            value={itemData.scanning_status}
            sx={{ background: "white", borderRadius: "12px" }}
            label="Scanning Status"
            name="scanning_status"
            onChange={(e) => handleChange(e.target.name, e.target.value)}
            disabled={disableInstanceEdit}
          >
            <MenuItem value={'DO_NOT_SCAN'}>{t(scanningStatusMap['DO_NOT_SCAN'])}</MenuItem>
            <MenuItem value={'CANNOT_SCAN'}>{t(scanningStatusMap['CANNOT_SCAN'])}</MenuItem>
            <MenuItem value={'TO_BE_SCANNED'}>{t(scanningStatusMap['TO_BE_SCANNED'])}</MenuItem>
            <MenuItem value={'SCANNING_IN_PROGRESS'}>{t(scanningStatusMap['SCANNING_IN_PROGRESS'])}</MenuItem>
            <MenuItem value={'ALREADY_SCANNED'}>{t(scanningStatusMap['ALREADY_SCANNED'])}</MenuItem>
            <MenuItem value={'TO_BE_RE_SCANNED'}>{t(scanningStatusMap['TO_BE_RE_SCANNED'])}</MenuItem>
          </Select>
        </FormControl>
        
        <FormControl sx={{ my: 1, flexDirection: 'row', alignItems: 'center' }} variant="outlined" fullWidth>
          <FormControlLabel
            control={
              <Checkbox
                checked={itemData.restricted_access == true}
                onChange={(e) => handleChange("restricted_access", e.target.checked)}
                name="restricted_access"
                color="primary"
                disabled={disableInstanceEdit}
              />
            }
            label={t("Is this a restricted item?")}
          />
          <Tooltip leaveDelay={3500} title={t("Please mark this box if the item should not be openly accessible to all library members")} enterTouchDelay={0} arrow>
            <InfoOutlinedIcon fontSize='small' sx={{color: '#3183FF'}}/>
          </Tooltip>
        </FormControl>
        
        <FormControl sx={{ mb: 1, flexDirection: 'row', alignItems: 'center' }} variant="outlined" fullWidth>
          <FormControlLabel
            control={
              <Checkbox
                checked={review}
                onChange={(e) => setReview(e.target.checked)}
                name="review"
                color="primary"
                disabled={disableInstanceEdit}
              />
            }
            label={t("Please review item entry")}
          />
          <Tooltip leaveDelay={3500} title={t("Please mark this box if you need clarification on the details entered for this particular item, and would like DoRC to review them.")} enterTouchDelay={0} arrow>
            <InfoOutlinedIcon fontSize='small' sx={{color: '#3183FF'}}/>
          </Tooltip>
        </FormControl>

        <Typography sx={{ mt: 2, px: 1 }} fontWeight={600}>
          {t("Copy Details")}
        </Typography>
        <FormControl sx={{ mb: 1, mt: 1, flexDirection: 'row', alignItems: 'center' }} variant="outlined" fullWidth>
          <FormControlLabel
            control={
              <Checkbox
                checked={itemData.condition === "Valuable"}
                onChange={(e) => handleChange("condition", e.target.checked)}
                name="condition"
                color="primary"
              />
            }
            label={t("Is this a valuable item?")}
          />
          <Tooltip leaveDelay={3500} title={t("Please mark the item as a valuable resource if it is - Fragile, Precious, Rare.")} enterTouchDelay={0} arrow>
            <InfoOutlinedIcon fontSize='small' sx={{color: '#3183FF'}}/>
          </Tooltip>
        </FormControl>

        <FormControl sx={{ my: 1, mt: 1 }} variant="outlined" fullWidth>
          <InputLabel
            sx={{ fontWeight: 400, color: "#949494" }}
            htmlFor="outlined-source"
          >
            {t("Source")}
          </InputLabel>
          <Select
            labelId="source_select"
            id="source_select"
            value={source}
            sx={{ background: "white", borderRadius: "12px" }}
            label="Source"
            name="source"
            onChange={(e) => setSource(e.target.value)}
          >
            <MenuItem value={'Borrowed'}>{t('Borrowed')}</MenuItem>
            <MenuItem value={'Donated'}>{t('Donated')}</MenuItem>
            <MenuItem value={'Purchased'}>{t('Purchased')}</MenuItem>
          </Select>
        </FormControl>

        <FormControl sx={{ my: 1, mt: 1, mb: 4 }} variant="outlined" fullWidth>
          <InputLabel
            sx={{ fontWeight: 400, color: "#949494" }}
            htmlFor="outlined-adornment-password"
          >
            {t("Call Number")}
          </InputLabel>
          <OutlinedInput
            id="outlined-adornment-password"
            sx={{ background: "white", borderRadius: "12px" }}
            value={temporaryAccessionCode}
            onChange={(e) => handleChange(e.target.name, e.target.value)}
            name="temporary_accession_code"
            label={t("Call Number")}
            inputProps={{ maxLength: 15 }}
          />
        </FormControl>
        
        <Snackbar open={open} autoHideDuration={2000} onClose={handleClose} sx={{zIndex: 99999}}>
          <Alert
            onClose={handleClose}
            severity="error"
            variant="filled"
            sx={{ width: '100%'}}
          >
            <Typography color={'white'}>
              {errorMessage}
            </Typography>
          </Alert>
        </Snackbar>
      </Box>

      <Box margin={1.5}>
        <Button
          variant="contained"
          onClick={handleClickSave}
          disabled={isSaveLoading}
          sx={{
            textTransform: "none",
            py: 2,
            borderRadius: 150,
            width: "100%",
          }}
        >
         {isSaveLoading ? <CircularProgress size={"24px"} sx={{color:"white" }} /> : 
         <Typography
            fontSize={16}
            fontWeight={400}
            color="white"
            letterSpacing={"0.96px"}
          >
            {t("Save")}
          </Typography>} 
        </Button>
      </Box>
      <InstanceSelector
        open={showInstanceSelector}
        setOpen={setShowInstanceSelector}
        setSelection={(selectedInstance) => selectFetchedInstance(selectedInstance)}
        searchType={instanceSearchType}
        initialInput={instanceSearchType === 'isbn' ? itemData.isbn : itemData.title}
      />
    </Container>
  );
}
