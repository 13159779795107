import { Container, Box, Typography } from '@mui/material';
import styled from 'styled-components';
import logo from '../assets/img/welcomelogo.svg';
import image from '../assets/img/welcomeimg.png';
import ctc from '../assets/img/ctc.png';
import us from '../assets/img/us.png';
import usaid from '../assets/img/usaid.png';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';

export default function Welcome() {
  const navigate = useNavigate();
  const { authData } = useAuth();

  useEffect(() => {
    const clickEvent = () => navigate('/language-screen');
    
    if (authData !== null) {
      navigate('/landing');
    } else {
      const timeoutId = setTimeout(() => {
        navigate('/language-screen');
      }, 3500);

      window.addEventListener("click", clickEvent);

      return () => {
        clearTimeout(timeoutId); 
        window.removeEventListener("click", clickEvent);
      };
    }
  }, [authData]);

  return (
    <StyledContainer disableGutters>
      <ImageWrapper>
        <LogoImage src={ctc} alt="CTC" />
        <USImagesWrapper>
          <USImage src={us} alt="US" />
          <USaidImage src={usaid} alt="USAID" />
        </USImagesWrapper>
      </ImageWrapper>
      <StyledTypographyPrimary>བོད་ཀྱི་དཔེ་མཛོད་འཛིན་སྐྱོང་མ་ལག</StyledTypographyPrimary>
      <img src={logo} alt="Logo" />
      <StyledTypographySecondary>Tibetan Library Management System</StyledTypographySecondary>
      <WallpaperImg src={image} alt="Image" />
      <Typography>This application is made possible by the support of the American People through the United States Agency for International Development (USAID.) The contents of this application do not necessarily reflect the views of USAID or the United States Government.</Typography>
    </StyledContainer>
  );
}


const StyledContainer = styled(Container)`
  text-align: center;
  padding-top: 16px;
  background-color: #FCFAF8;
  overflow: hidden;
`;

const ImageWrapper = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 16px;
  align-items: center;
  padding-bottom: 64px;
`;

const LogoImage = styled.img`
  width: 42px;
  height: 38px;
`;

const USImagesWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const USImage = styled.img`
  width: 23px;
  height: 23px;
`;

const USaidImage = styled.img``;

const StyledTypographyPrimary = styled.div`
  color: #6D2312;
  font-size: 24px;
  font-weight: 700;
  letter-spacing: 1.54px;
  text-align: center;
  padding-bottom: 48px;
`;

const StyledTypographySecondary = styled.div`
  color: #6D2312;
  font-size: 24px;
  font-weight: 700;
  letter-spacing: 1.44px;
  text-align: center;
  padding: 32px;
`;

const WallpaperImg = styled.img`
  width: -webkit-fill-available; 
  marginTop: -54px;
`;

