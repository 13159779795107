import { UserDetails } from "../types";
import { CREATE_USER_API_ENDPOINT, USERS_API_ENDPOINT } from "../utils/api";
import { authenticatedFetch } from "./utils";

export async function getUsers(
  onlyAssociates: boolean,
  offset: number = 0,
  limit: number = 10
): Promise<UserDetails[]> {
  let url = `${USERS_API_ENDPOINT}?offset=${offset}&limit=${limit}`;
  if (onlyAssociates) {
    url += "&only_associate=true";
  }
  const response = await authenticatedFetch(url);
  if (response.ok) {
    const data: UserDetails[] = await response.json();
    return data;
  } else {
    console.error("Failed to get items");
    return [];
  }
}

export async function createUser(
  userData: UserDetails
): Promise<UserDetails | null> {
  const response = await authenticatedFetch(
    CREATE_USER_API_ENDPOINT,
    userData,
    "POST",
  );
  if (response.ok) {
    const data: UserDetails = await response.json();
    return data;
  } else {
    console.error("Failed to get items");
    return null;
  }
}
