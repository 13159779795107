import React from 'react';
import { Box, Typography } from '@mui/material';
import styled from 'styled-components';

interface HeaderProps {
  t: (key: string) => string;
  logo: string;
}

const HomeHeader: React.FC<HeaderProps> = ({ t, logo }) => (
  <StyledBox>
    <img src={logo} alt="Logo" />
    <StyledTypography>
      {t('Home')}
    </StyledTypography>
    <Spacer />
  </StyledBox>
);

export default HomeHeader;

const StyledBox = styled(Box)`
  display: flex;
  flex-direction: row;
  padding: 16px;
  background-color: white;
  width: 100vw;
  max-width: 100%;
  justify-content: space-between;
`;

const StyledTypography = styled(Typography)`
  font-size: 16px;
  font-weight: 500;
`;

const Spacer = styled(Box)`
  width: 30px;
`;