import { Box, Typography } from '@mui/material';

import FormInput from './FormInput';
import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

interface LibraryStatisticsProps {
  numBooks: string;
  setNumBooks: React.Dispatch<React.SetStateAction<string>>;
  numManuscripts: string;
  setNumManuscripts: React.Dispatch<React.SetStateAction<string>>;
  numShelves: string;
  setNumShelves: React.Dispatch<React.SetStateAction<string>>;
  numMiscItems: string;
  setNumMiscItems: React.Dispatch<React.SetStateAction<string>>;
  numWoodblockPrints: string;
  setNumWoodblockPrints: React.Dispatch<React.SetStateAction<string>>;
  numRooms: string;
  setNumRooms: React.Dispatch<React.SetStateAction<string>>;
  numFloors: string;
  setNumFloors: React.Dispatch<React.SetStateAction<string>>;
}

const LibraryStatistics: React.FC<LibraryStatisticsProps> = ({
  numBooks,
  setNumBooks,
  numManuscripts,
  setNumManuscripts,
  numShelves,
  setNumShelves,
  numMiscItems,
  setNumMiscItems,
  numWoodblockPrints,
  setNumWoodblockPrints,
  numRooms,
  setNumRooms,
  numFloors,
  setNumFloors,
}) => {
  const { t } = useTranslation();

  return (
    <StyledBox>
      <StyledTypography>{t('Total number of floors')}</StyledTypography>
      <FormInput value={numFloors} onChange={(e) => setNumFloors(e.target.value)} id="floors" label={t('Floors')} />

      <StyledTypography>{t('Total number of rooms')}</StyledTypography>
      <FormInput value={numRooms} onChange={(e) => setNumRooms(e.target.value)} id="rooms" label={t('Rooms')} placeholder={t('eg. 10')} />

      <StyledTypography>{t('Total number of books')}</StyledTypography>
      <FormInput value={numBooks} onChange={(e) => setNumBooks(e.target.value)} id="books" label={t('Books')} placeholder={t('eg. 1653')} />

      <StyledTypography>{t('Total number of manuscripts')}</StyledTypography>
      <FormInput value={numManuscripts} onChange={(e) => setNumManuscripts(e.target.value)} id="manuscripts" label={t('Manuscripts')} placeholder={t('eg. 123')} />

      <StyledTypography>{t('Total number of shelves')}</StyledTypography>
      <FormInput value={numShelves} onChange={(e) => setNumShelves(e.target.value)} id="shelves" label={t('Shelves')} placeholder={t('eg. 50')} />

      <StyledTypography>{t('Total number of woodblocks')}</StyledTypography>
      <FormInput value={numWoodblockPrints} onChange={(e) => setNumWoodblockPrints(e.target.value)} id="woodblockPrints" label={t('Woodblock Prints')} placeholder={t('eg. 50')} />

      <StyledTypography>{t('Total number of miscellaneous items')}</StyledTypography>
      <FormInput value={numMiscItems} onChange={(e) => setNumMiscItems(e.target.value)} id="miscItems" label={t('Miscellaneous Items')} placeholder={t('eg. 100')} />
    </StyledBox>
  );
};

export default LibraryStatistics;


export const StyledBox = styled(Box)`
  overflow: scroll;
  width: 100vw;
  max-width: 100vw;
  padding-bottom: 64px;
`;

export const StyledTypography = styled(Typography)`
  font-weight: 600;
  padding: 16px;
  padding-bottom: 10px;
  padding-top: 20px
  font-size: 16px;
  color: #242424;
`;