import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';

interface SnackbarProps {
  open: boolean;
  severity: AlertProps['severity'];
  message: string;
  onClose: () => void;
  autoHideDuration?: number;
}

const GlobalSnackbar: React.FC<SnackbarProps> = ({
  open,
  severity,
  message,
  onClose,
  autoHideDuration = 4000,
}) => {
  return (
    <Snackbar open={open} autoHideDuration={autoHideDuration} onClose={onClose} anchorOrigin={{ vertical:"bottom", horizontal:"center" }} sx={{zIndex: 99999}}>
      <MuiAlert elevation={6} variant="filled" severity={severity} onClose={onClose} style={{ width: '100%', borderRadius: "14px" }}>
        {message}
      </MuiAlert>
    </Snackbar>
  );
};

export default GlobalSnackbar;