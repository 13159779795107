import React from 'react'
import { Button, Container, Typography, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next'
import logo from '../assets/img/Frame.svg';
import language from '../assets/img/lang.png';
import { useNavigate } from 'react-router-dom';

export default function LanguageScreen() {
    const { t, i18n } = useTranslation()
    const [lang, setLang] = React.useState<string>()
    const navigate = useNavigate();

    const handleClick = (language: string) => {
      console.log(language)
      setLang(language)
      localStorage.setItem('lang', language);
      i18n.changeLanguage(language)
    };
    
  return (
    <Container disableGutters maxWidth={false} sx={{height: '100vh', textAlign: 'center', paddingTop: '2rem'}}>
        <img src={logo} />
        
        <Typography variant='h5' component='h2' fontSize={24} fontWeight={600} mt={3} color={'#6D2312'}>
            དགའ་བསུ་ཞུ།
        </Typography>

        <Typography variant='h5' component='h2' fontSize={24} fontWeight={600} mt={2} color={'#6D2312'}>
            Tashi Delek
        </Typography>

        <Typography variant='h5' component='h4' fontSize={22} fontWeight={400} mt={4} px={'2rem'} textAlign={'left'}>
            སྐད་ཡིག་འདེམས།
        </Typography>

        <Typography variant='h5' component='h4' fontSize={16} fontWeight={400} mt={2} px={'2rem'} textAlign={'left'}>
            Please select the language of your choice.
        </Typography>

        <Stack spacing={1} direction="row" justifyContent='space-between' mt={3} px={'2rem'} mb={6}>
            <Button onClick={() => handleClick('bo')} color={lang == 'bo' ? "primary" : "secondary"} variant="outlined" sx={{alignItems: 'center', textTransform: 'none', borderRadius: "8px", px: 5}}>
                <Typography  fontSize={20} fontWeight={400} my={2} color={lang=='bo' ? "primary" : "secondary"}>
                    བོད་ཡིག
                </Typography>
            </Button>
            <Button onClick={() => handleClick('en')} color={lang == 'en' ? "primary" : "secondary"} variant="outlined" sx={{alignItems: 'center', textTransform: 'none', borderRadius: "8px", px: 5}}>
                <Typography  fontSize={20} fontWeight={400} my={2} color={lang=='en' ? "primary" : "secondary"}>
                    English
                </Typography>
            </Button>
        </Stack>

        <img src={language} style={{width: 'inherit', opacity: 0.3}}/>

        <Button onClick={() => navigate('/login')} variant="contained" disabled={lang == 'en' || lang == 'bo' ? false : true} sx={{textTransform: 'none', py: 2, borderRadius: 150, position: 'absolute', bottom: '5vh', width: '90%', left: '50%', transform: 'translateX(-50%)', maxWidth: '328px'}}>
            <Typography  fontSize={16} fontWeight={400} color='white' letterSpacing={'0.96px'}>
                {t('Confirm')}
            </Typography>
        </Button>
    </Container>
  )
}