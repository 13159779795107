import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import translationsData from "./translations.json";

const languageMap = {
	en: 'English',
	bo: 'Tibetan'
};

interface TranslationDataItem {
	[languageCode: string]: string; // Dynamic keys for different languages
}
  
interface Translations {
	[languageCode: string]: {
		translation: Record<string, string>;
	};
}

/**
 * Convert [{English:"e",Tibetan:"t"}] to {en:{"e":"e"}, bo:{"e":"t"}}
 */
function loadTranslationsToI18n(): Translations{
	const translations: Translations = {};

	// Initialize translation objects for each supported language
	Object.keys(languageMap).forEach(languageCode => {
		translations[languageCode] = { translation: {} };
	});

	(translationsData as TranslationDataItem[]).forEach(item => {
		Object.entries(languageMap).forEach(([languageCode, languageName]) => {
		  const translatedText = item[languageName];
		  if (translatedText && item.English) {
			translations[languageCode].translation[item.English] = translatedText;
		  }
		});
	});

	return translations;
}  

i18n.use(initReactI18next).init({
	lng: localStorage.getItem('lang') || 'en',
	fallbackLng: 'en',
	compatibilityJSON: 'v3',
	resources: loadTranslationsToI18n(),
	interpolation: {
		escapeValue: false // React already safe from XSS
	}
})

export default i18n;