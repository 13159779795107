import React, { useState, useRef, ChangeEvent } from 'react';
import { Close, FileUploadOutlined } from '@mui/icons-material';
import {
  Box,
  Button,
  IconButton,
  Input,
  Typography,
  Modal,
  Fade,
} from '@mui/material';
import imageCompression from 'browser-image-compression';
import { v4 as uuidv4 } from 'uuid';
import { useSnackbar } from './SnackbarProvider';
import ReactCrop, { convertToPixelCrop, Crop } from 'react-image-crop';
import setCanvasPreview from './setCanvasPreview';
import 'react-image-crop/dist/ReactCrop.css';
import FullPageLoader from './FullPageLoader'; // Import the full-page loader

const MIN_DIMENSION = 150;
import { useTranslation } from 'react-i18next';

type ImageUploadProps = {
  image: string;
  onImageChange: (file: File) => void;
  onRemoveImage: () => void;
  label: string;
  componentID: string;
  fullImage?: string;
  disabled?: boolean;
};

const ImageUpload: React.FC<ImageUploadProps> = ({
  image,
  onImageChange,
  onRemoveImage,
  label,
  componentID,
  fullImage,
  disabled = false,
}) => {
  const [showLoading, setShowLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const { showMessage } = useSnackbar();
  const [imgSrc, setImgSrc] = useState('');
  const imgRef = useRef<HTMLImageElement>(null);
  const [crop, setCrop] = useState<Crop>({
    unit: '%',
    width: 50,
    height: 50,
    x: 25,
    y: 25,
  });
  const [cropModalOpen, setCropModalOpen] = useState(false);
  const [fileName, setFileName] = useState('');
  const previewCanvasRef = useRef<HTMLCanvasElement | null>(null);
  const [imageFile, setImageFile] = useState<File | null>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const { t } = useTranslation();

  const handleImageUpload = async (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file) return;
  
    const options = {
      maxSizeMB: 0.5,
      maxWidthOrHeight: 1280,
      useWebWorker: true,
    };
    setShowLoading(true);
  
    try {
      const compressedFile = await imageCompression(file, options);
  
      const fileExtension = compressedFile.name.split('.').pop();
      const newFileName = `${uuidv4()}.${fileExtension}`;
      const newFile = new File([compressedFile], newFileName, { type: compressedFile.type });
      onImageChange(newFile);
      setFileName(newFileName);
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        const imageElement = new Image();
        const imageUrl = reader.result?.toString() || '';
        imageElement.src = imageUrl;

        imageElement.addEventListener('load', (e) => {
          const { naturalWidth, naturalHeight } =
            e.currentTarget as HTMLImageElement;
          if (naturalWidth < MIN_DIMENSION || naturalHeight < MIN_DIMENSION) {
            showMessage(
              'Image must be at least 150 x 150 pixels.',
              'error',
              4000,
            );
            return setImgSrc('');
          }
        });
  
        setImgSrc(imageUrl);
      });
  
      // Ensure to read the image file after setting the src
      reader.readAsDataURL(compressedFile);
      setImageFile(compressedFile);
      // setCropModalOpen(true);
    } catch (error) {
      showMessage("Error during image compression","error",4000)
    } finally {
      setShowLoading(false);
    }
  };

  const handleOpenModal = () => setModalOpen(true);
  const handleCloseModal = () => setModalOpen(false);

  const handleCaptureFromCamera = async () => {
    try {
      const permissionStatus = await navigator.permissions.query({
        name: 'camera' as PermissionName,
      });
      if (
        permissionStatus.state !== 'granted' &&
        permissionStatus.state !== 'prompt'
      ) {
        showMessage(
          'Camera permission is denied. Please allow camera access in your browser settings.',
          'error',
          4000,
        );
      }
    } catch (error) {
      showMessage(
        'An error occurred while checking camera permissions.',
        'error',
        4000,
      );
    }
  };


  const handleSaveCropImage = async () => {
    if (!imgRef.current) {
      showMessage('Image is not available', 'error', 4000);
      return;
    }

    if (!crop) {
      setShowLoading(true);
      if(imageFile)
        onImageChange(imageFile);
      setCropModalOpen(false);
      return;
    }
  
    const hasCropChanged = crop.width !== 100 || crop.height !== 100;
  
    if (hasCropChanged && previewCanvasRef.current) {
      setCanvasPreview(
        imgRef.current,
        previewCanvasRef.current,
        convertToPixelCrop(crop, imgRef.current.width, imgRef.current.height),
      );
      setShowLoading(true);
      previewCanvasRef.current.toBlob(async (blob) => {
        if (blob) {
          const croppedFile = new File([blob], `cropped_${fileName}`, {
            type: 'image/png',
          });
          onImageChange(croppedFile);
        } else {
          showMessage("Failed to create a Blob from the canvas.", 'error', 4000);
        }
        setShowLoading(false);
        setCropModalOpen(false);
      }, 'image/png');
    } else {
      setCropModalOpen(false);
    }
  };

  const handleCloseCropModal = () => {
    setCrop(undefined);
    setImgSrc('');
    setImageFile(null);
    setCropModalOpen(false);
    onRemoveImage();
  };

  return (
    <>
      {showLoading && <FullPageLoader />}

      <Modal
        open={cropModalOpen}
        onClose={handleCloseCropModal}
        closeAfterTransition
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <Fade in={cropModalOpen}>
          <Box
            sx={{
              position: 'relative',
              width: '90vw',
              height: '90vh',
              maxWidth: '1200px',
              maxHeight: '90vh',
              bgcolor: 'background.paper',
              boxShadow: 24,
              p: 2,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <IconButton
              aria-label="remove-crop-image"
              disabled={disabled}
              onClick={handleCloseCropModal}
              sx={{
                position: 'absolute',
                top: 8,
                right: 8,
                color: 'error.main',
              }}
            >
              <Close color="error" />
            </IconButton>
            <ReactCrop
              crop={crop}
              onChange={(_, percentCrop) => setCrop(percentCrop)}
              keepSelection
              minWidth={MIN_DIMENSION}
            >
              <img
                ref={imgRef}
                src={imgSrc}
                alt="Upload"
                style={{ maxHeight: '70vh' }}
              />
            </ReactCrop>
            <Button
              onClick={handleSaveCropImage}
              sx={{ position: 'absolute', bottom: 15, right: 10 }}
            >
              Save
            </Button>
            {crop && (
              <canvas
                ref={previewCanvasRef}
                className="mt-4"
                style={{
                  display: 'none',
                  border: '1px solid black',
                  objectFit: 'contain',
                  width: 150,
                  height: 150,
                }}
              />
            )}
          </Box>
        </Fade>
      </Modal>

      {image && (
        <Box
          sx={{
            border: '1px solid #DFDFDF',
            borderRadius: '11px',
            position: 'relative',
            width: '112px',
            minWidth: '112px',
            height: '150px',
            minHeight: '150px',
            cursor: 'pointer',
          }}
          onClick={handleOpenModal}
        >
          <img
            src={image}
            alt="Selected Image"
            style={{ width: '112px', height: '150px', borderRadius: '11px' }}
          />
          <IconButton
            aria-label="remove-image"
            disabled={disabled}
            onClick={(e) => {
              if (!disabled) {
                e.stopPropagation();
                onRemoveImage();
              }
            }}
            sx={{ position: 'absolute', top: 0, right: 0, color: 'error.main' }}
          >
            <Close color="error" />
          </IconButton>
        </Box>
      )}

      <Modal
        open={modalOpen}
        onClose={handleCloseModal}
        closeAfterTransition
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <Fade in={modalOpen}>
          <Box
            sx={{
              outline: 'none',
              p: 2,
              bgcolor: 'background.paper',
              border: '2px solid #000',
              boxShadow: 24,
              position: 'relative',
              width: 'auto',
              maxWidth: '90vw',
              maxHeight: '90vh',
            }}
          >
            <IconButton
              onClick={handleCloseModal}
              sx={{
                position: 'absolute',
                right: -2,
                top: -2,
                color: 'red',
              }}
              aria-label="close"
            >
              <Close />
            </IconButton>
            <img
              src={fullImage || image}
              alt="Full Size"
              style={{ maxWidth: '100%', maxHeight: '90vh' }}
            />
          </Box>
        </Fade>
      </Modal>

      {!image && (
        <Box>
          <Button
            id={`upload_${componentID}`}
            component="label"
            role={undefined}
            variant="outlined"
            tabIndex={-1}
            sx={{
              width: '112px',
              height: '108px',
              flexDirection: 'column',
              textTransform: 'none',
              borderRadius: '11px',
              borderWidth: '1px',
              borderColor: '#DFDFDF',
              minWidth: '112px',
              backgroundColor: 'white',
            }}
          >
            <Typography sx={{ pb: '20px', color: '#949494', fontSize: '12px' }}>
              {label}
            </Typography>
            <FileUploadOutlined />
            <Input
              id={componentID}
              sx={{
                clip: 'rect(0 0 0 0)',
                clipPath: 'inset(50%)',
                height: 1,
                overflow: 'hidden',
                position: 'absolute',
                bottom: 0,
                left: 0,
                whiteSpace: 'nowrap',
                width: 1,
              }}
              type="file"
              disabled={disabled}
              inputProps={{ accept: 'image/*' }}
              onChange={handleImageUpload}
              ref={inputRef}
            />
          </Button>

          <Button
            component="label"
            onClick={handleCaptureFromCamera}
            sx={{ marginTop: '10px', textTransform: 'none' }}
          >
            {t("Capture from Camera")}
            <Input
              id={`${componentID}_camera`}
              sx={{
                clip: 'rect(0 0 0 0)',
                clipPath: 'inset(50%)',
                height: 1,
                overflow: 'hidden',
                position: 'absolute',
                bottom: 0,
                left: 0,
                whiteSpace: 'nowrap',
                width: 1,
              }}
              disabled={disabled}
              type="file"
              inputProps={{ accept: 'image/*', capture: 'environment' }}
              onChange={handleImageUpload}
              ref={inputRef}
            />
          </Button>
        </Box>
      )}
    </>
  );
};

export default ImageUpload;
