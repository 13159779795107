import React, { useEffect, useState } from 'react';
import {
  Box, Grid, Typography, Avatar, CircularProgress, Alert, Button, Modal, TextField, Fade, Backdrop,
} from '@mui/material';
import { getInstanceComments } from '../api/comments';
import { addInstanceComments } from '../api/comments';  // Import your add function
import { useAuthenticatedRequest } from '../hooks/AuthenticatedApiCallHook';
import { useTranslation } from 'react-i18next';
import { InstanceComment } from '../types';

interface InstanceCommentsProps {
  instanceId: string;
}

const InstanceComments: React.FC<InstanceCommentsProps> = ({ instanceId }) => {
  const api = useAuthenticatedRequest();
  const { t } = useTranslation();

  const [comments, setComments] = useState<InstanceComment[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [open, setOpen] = useState(false);
  const [newComment, setNewComment] = useState<string>('');
  const [submitting, setSubmitting] = useState<boolean>(false);

  useEffect(() => {
    const fetchComments = async () => {
      setLoading(true);
      try {
        const data = await getInstanceComments(api, instanceId);
        setComments(data);
        setError(null);
      } catch (err) {
        setError((err as Error).message);
      } finally {
        setLoading(false);
      }
    };

    fetchComments();
  }, [instanceId]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleSubmit = async () => {
    if (!newComment.trim()) return; // Don't submit empty comments

    setSubmitting(true);
    try {
      const addedComment = await addInstanceComments(api, instanceId, newComment);
      setComments((prevComments) => [
        {
          id: addedComment.id,
          username: addedComment.user.username, // Adjust based on your API response structure
          avatarSrc: '/placeholder.svg?height=40&width=40', // Adjust if needed
          comment: addedComment.comment,
          created_at: new Date(addedComment.created_at).toLocaleDateString(),
        },
        ...prevComments,
      ]);
      setNewComment(''); // Clear the input field
      handleClose(); // Close the modal after successful submission
    } catch (err) {
      setError((err as Error).message);
    } finally {
      setSubmitting(false);
    }
  };

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    return <Alert severity="error">{error}</Alert>;
  }

  return (
    <Box sx={{ width: '100%', margin: 'auto' }}>
      {comments.length === 0 ? (
        <Typography variant="body1" color="text.secondary">{t("No comments available")}</Typography>
      ) : (
        comments.map((comment) => (
          <Grid container key={comment.id} spacing={2} sx={{ mb: 3 }}>
            <Grid item>
              <Avatar src={comment.avatarSrc} alt={comment.username} />
            </Grid>
            <Grid item xs>
              <Typography fontSize={'14px'} fontWeight={400}>
                {comment.username}
              </Typography>
              <Typography fontSize={'14px'} fontWeight={400}>
                {comment.comment}
              </Typography>
              <Typography variant="caption" color="text.secondary">
                {comment.created_at}
              </Typography>
            </Grid>
          </Grid>
        ))
      )}

      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 2 }}>
        <Button variant="contained" color="primary" onClick={handleOpen}>
          {t("Add a Comment")}
        </Button>
      </Box>

      {/* Modal for adding a new comment */}
      <Modal
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500 }}
      >
        <Fade in={open}>
          <Box
            sx={{
              position: 'absolute' as 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 400,
              bgcolor: 'background.paper',
              boxShadow: 24,
              p: 4,
            }}
          >
            <Typography variant="h6" component="h2" gutterBottom>
              {t("Add a Comment")}
            </Typography>
            <TextField
              label={t("Comment")}
              fullWidth
              multiline
              rows={4}
              value={newComment}
              onChange={(e) => setNewComment(e.target.value)}
              variant="outlined"
              sx={{ mb: 2 }}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              disabled={submitting}
            >
              {submitting ? t('Save') + '...' : t('Save')}
            </Button>
          </Box>
        </Fade>
      </Modal>
    </Box>
  );
};

export default InstanceComments;
